//qa
//export const API_ROOT_URL = "http://23.20.194.202:8080/LMS/api/v1/";

//live
export const API_ROOT_URL = "https://lms.lucky1.lk/LMS/api/v1";

//sajith local
// export const API_ROOT_URL = "http://192.168.2.105:8080/api/v1";

export const LOTTERY_BUY_COUNT = 1;
export const LOTTERY_SUBCRIBE_COUNT = 1;

export const MODALS = {
  BUY: "MODAL_BUY",
  SUBSCRIBE: "MODAL_SUBSCRIBE",
  SEARCH: "MODAL_SEARCH",
  LOGIN: "MODAL_LOGIN",
  WARNING: "MODAL_WARNING",
  SIGNUP: "MODAL_SIGNUP",
  PINVERIFY: "MODAL_PINVERIFY",
  PASSWORDFORGET: "MODAL_PASSWORD_FORGET",
  PASSWORDRESET: "MODAL_PASSWORD_RESET",
  NIC: "MODAL_NIC",
  MODALMOBILEENTER: "MODAL_MOBILE_ENTER"
};

export const EVENTS = {
  RANDOM_BUY: "EVENT_RANDOM_BUY",
  SUBSCRIBE: "EVENT_SUBSCRIBE",
  ACCOUNT: "EVENT_ACCOUNT",
  BUY: "EVENT_BUY",
  DEACTIVATE: "EVENT_DEACTIVATE",
  TEMP_TOKEN: "TEMP_TOKEN",
  BUYALL: "BUYALL"
};

export const LIMIT = 12;
export const SEARCH_LIMIT = 100;

export const HOTJAR = {
  HJID: "793107",
  HJSV: "6"
};

export const GOOGLE_ANALYTICS = {
  PROD_T_C: "UA-140783781-6",
  QA_T_C: "UA-91665095-1"
};
//live
export const GOOGLE_SIGN_IN = {
  GOOGLE_LIVE:
    "1009481181622-um1e9mpmfko1slnpti7fdn3vornj29hd.apps.googleusercontent.com"
};

export const FACEBOOK_SIGN_IN = {
  FACEBOOK_ID: "189330161821065"
};

export const WEEK_DAYS = [
  { id: "Sat", name: "Saturday" },
  { id: "Sun", name: "Sunday" },
  { id: "Mon", name: "Monday" },
  { id: "Tue", name: "Tuesday" },
  { id: "Wed", name: "Wednesday" },
  { id: "Thu", name: "Thursday" },
  { id: "Fri", name: "Friday" }
];

export const CONVENIENT_FEE =
  "Rs.4 convenient fee + tax will apply for each ticket";

//Dialog Ad API
export const SIMATO_AFFID = "1";
export const SIMATO_USERID = "zm";

export const LOTTERY_LIST = [
  { key: "supiri_vasana", value: "Supiri Vasana" },
  { key: "mega_power", value: "Mega Power" },
  { key: "govisetha", value: "Govi Setha" },
  { key: "mahajana_sampatha", value: "Mahajana Sampatha" },
  { key: "neeroga", value: "Neeroga" },
  { key: "jathika_sampatha", value: "Jathika Sampatha" },
  { key: "dhana_nidhanaya", value: "Dhana Nidhanaya" },
  { key: "sevana", value: "Sevana" },
  { key: "vasana_sampatha", value: "Vasana Sampatha" },
  { key: "delakshapathi_double_dreams", value: "Delakshapathi Double Dreams" },
  { key: "sampath_rekha", value: "Sampath Rekha" },
  { key: "test", value: "Test" }
];
