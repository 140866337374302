import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import thunk from "redux-thunk";
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { hotjar } from 'react-hotjar';
import { createBrowserHistory } from 'history';


import './styles/css/main.css';

import reducers from './reducers';

import Home from './containers/Home';
import Results from './containers/Results';
import Account from './containers/Account';
import Terms from './containers/terms';
import About from './containers/About';
import HowItWorks from './containers/HowItWorks';
import PrivacyPolicy from './containers/PrivacyPolicy';


import Menu from './containers/Menu';
import NotificationBar from './containers/NotificationBar';
import Footer from './components/Footer';

import LoginModal from './containers/LoginModal';
import BuyModal from './containers/BuyModal';
import SearchModal from './containers/SearchModal';
import SubscribeModal from './containers/SubscribeModal';
import WarningModal from './containers/WarningModal';
import SignUpModal from './containers/SignUpModal';
import PinVerifyModal from './containers/pinVerifyModal';
import PasswordForgetModal from './containers/PasswordForgetModal';
import PasswordResetModal from './containers/PasswordResetModal';
import NICModal from './containers/NicModal';
import AdvertModal from './containers/AdvertModal';
import MobileEnterModal from './containers/MobileEnterModal';
import { HOTJAR, GOOGLE_ANALYTICS } from './config/';
import withTracker from './utils/withTracker';


import ReactGA from 'react-ga';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


//ReactGA.initialize(GOOGLE_ANALYTICS.QA_T_C, { debug: true });
ReactGA.initialize(GOOGLE_ANALYTICS.PROD_T_C, { debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);




// function fireTracking() {
//     ReactGA.pageview(window.location.hash);
// }
// ReactGA.pageview(window.location.pathname + window.location.hash);


// const history = createBrowserHistory();

// history.listen((location, action) => {
//   window.ga.set({ page: location.pathname });
//   window.ga.pageview(location.pathname);
// });



const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            thunk,
            loadingBarMiddleware()
        )
    )
);

  store.subscribe(() => {
      localStorage.setItem('TOKEN', store.getState().token);
      localStorage.setItem('language-loaded', store.getState().language);
  });

  hotjar.initialize(HOTJAR.HJID, HOTJAR.HJSV);



ReactDOM.render(

  <Provider store={store}>
    <Router>
    <div className="container-fluid">
        <div className="row menu-bg">
            <div className="container">
                <Menu />
            </div>
        </div>

        <div className="row">
            <div className="container">

                <NotificationBar />
                <Switch>
                  <Route path="/about" component={withTracker(About)} />
                  <Route path="/howItWorks" component={withTracker(HowItWorks)} />
                  <Route path="/terms" component={withTracker(Terms)} />
                  <Route path="/privacyPolicy" component={withTracker(PrivacyPolicy)} />
                  <Route path="/results" component={withTracker(Results)} />
                  <Route path="/account" component={withTracker(Account)} />
                  <Route path="/:id" component={withTracker(Home)} />
                  <Route path="/" component={withTracker(Home)} />
                </Switch>

                <LoginModal />
                <BuyModal />
                <SearchModal />
                <SubscribeModal />
                <WarningModal />
                <SubscribeModal />
                <SignUpModal/>
                <PinVerifyModal/>
                <PasswordForgetModal/>
                <PasswordResetModal/>
                <NICModal/>
                <MobileEnterModal/>
                <AdvertModal/>
            </div>
        </div>

        <div className="row footer-bg">
            <div className="container-fluid px-0">
                <Footer />
            </div>
        </div>
    </div>
    </Router>
</Provider>, document.getElementById('root'));
