import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openDialog } from "redux-reactstrap-modal";
import {
  Search as SearchIcon,
  ShoppingCart as BuyIcon,
  FolderPlus as SubscribeIcon,
  XSquare as CloseIcon
} from "react-feather";
import axios from "axios";
import _ from "lodash";

import { LOTTERY_LIST } from "../config/";

import ticket_image from "../images/ticket-img2.png";

import { setNextEvent } from "../actions/index";
import { translate, getLanguage } from "react-switch-lang";
class HomeLottery extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //     sold_out: false
    // }
  }

  componentWillMount() {
    // const { lottery: { draw_id }, packages } = this.props;
    // var self = this;
    // const url = `${API_ROOT_URL}/public/lottery-tickets/search?limit=1&offset=0`;
    // axios.post(url, {
    //     draw_id: draw_id,
    //     fields: []
    // }).then(function (response) {
    //     if (response.data.page.total === 0) {
    //         self.setState({ sold_out: true });
    //     }
    // }).catch(function (error) {
    //     //console.log(error);
    // });
    // if(this.props.subcribeId){
    //   var pack = _.find(packages, { 'package_name': this.props.subcribeId })
    //   if (pack) {
    //     this.props.openDialog('MODAL_SUBSCRIBE', _.merge(pack, this.props.lottery))
    //   }else{
    //
    //   }
    // }
  }

  openSearch() {
    this.props.openDialog("MODAL_SEARCH", this.props.lottery);
  }

  buyTickets(ticket) {
    const { sharedStatus, token } = this.props;

    if (token === "" || token === null) {
      this.props.randomBuys(ticket);
    } else {
      // ticket.mobile_no = "";
      this.props.randomBuys(ticket);
    }
  }

  subcribeTicket(ticket) {
    const { sharedStatus, token } = this.props;

    if (token === "" || token === null) {
      this.props.subscribes(ticket);
    } else {
      // ticket.mobile_no = "";
      this.props.subscribes(ticket);
      // window.scrollTo(0, 0);
    }
  }

  renderBuyButtons() {
    const { t } = this.props;

    const {
      lottery_id,
      is_ongoing,
      dark_color,
      draw_id,
      is_search_enable,
      is_ticket_soldout,
    } = this.props.lottery;

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color
    };
    return (
      <div className="col-8">
        <div className="row">
          <div className="col-6 p-1">
            <button
              type="button"
              disabled={!is_ongoing ? "disabled" : ""}
              onClick={() =>
                this.props.openDialog("MODAL_BUY", this.props.lottery)
              }
              style={buttonStyle}
              className={"btn btn-search btn-block shadow-button buy-" + lottery_id}
            >
              <BuyIcon size={16} />
              <br />
              {t("home.buy")}
            </button>
          </div>
          <div className="col-6 p-1">
            <button
              type="button"
              disabled={
                is_search_enable
                  ? !is_ongoing || is_ticket_soldout
                    ? "disabled"
                    : ""
                  : "disabled"
              }
              onClick={() => this.openSearch()}
              style={buttonStyle}
              className={"btn btn-search btn-block shadow-button srch-" + lottery_id}
            >
              <SearchIcon size={16} />
              <br />
              {t("home.search")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderSubscribeButtons() {
    const { t } = this.props;
    const {
      lottery: { lottery_name, dark_color },
      packages,
      subcribeId,
      myPackages,
      sharedStatus
    } = this.props;


    const {
      lottery_id
    } = this.props.lottery;

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color
    };

    var pack = _.find(packages, { package_name: lottery_name });
    var package_id = "";
    var subcribe_success = false;

    if (subcribeId) {
      var packFilter = _.find(packages, {
        package_name: this.props.subcribeId
      });
      var lotteryFilter;
      if (this.props.lottery.lottery_name === this.props.subcribeId) {
        lotteryFilter = this.props.lottery;
      }

      if (packFilter) {
        // this.subcribeTicket({lotteryFilter.package_id, subscribe_count: LOTTERY_SUBCRIBE_COUNT, lottery_name:lottery_name })
      }
    }

    if (pack) {
      package_id = pack.package_id;

      var packMy = _.find(myPackages, { package_id: package_id });
      // var packMy2 = _.find(subcribe_list, { 'package_id': package_id })
      // console.log(package_id);
      if (sharedStatus) {
        if (sharedStatus.id === pack.package_id) {
          subcribe_success = true;
        }
      }
      if (packMy) {
        return (
          <button
            type="button"
            style={buttonStyle}
            disabled="disabled"
            className={
              "btn btn-search btn-block px-md-0 px-lg-1 btn-" + lottery_id
            }
          >
            <SubscribeIcon size={16} />
            <br />
            {t("home.subscribe")}
          </button>
        );
      } else {
        return (
          <button
            type="button"
            onClick={() =>
              this.props.openDialog(
                "MODAL_SUBSCRIBE",
                _.merge(pack, this.props.lottery)
              )
            }
            style={buttonStyle}
            className={
              "btn btn-search btn-block shadow-button px-md-0 px-lg-1 btn-" +
              package_id
            }
          >
            <SubscribeIcon size={16} />
            <br />
            {t("home.subscribe")}
          </button>
        );
      }
    } else {
      return (
        <button
          type="button"
          style={buttonStyle}
          disabled="disabled"
          className="btn btn-search btn-block px-md-0 px-lg-1"
        >
          <SubscribeIcon size={16} />
          <br />
          {t("home.subscribe")}
        </button>
      );
    }
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    return "lottery_tickets." + pack.key;
  }

  render() {
    const { t } = this.props;

    const {
      lottery_name,
      lottery_logo_url,
      jackpot_price,
      draw_number,
      draw_date_time,
      is_ongoing,
      light_color,
      dark_color,
      lottery_image_url,
      is_ticket_soldout
    } = this.props.lottery;

    var drawTimeLable = t("home.date") + " :" + draw_date_time.substr(0, 10);

    var backgroundStyle = {
      background: `linear-gradient(45deg, ${light_color}, ${dark_color})`,
      border: `1px solid ${light_color}`
    };

    var buttonStyle = {
      background: dark_color,
      borderColor: dark_color
    };

    return (
      <div className="mb-2 col-12 col-md-4 col-lg-3">
        <div
          className="bg-1 rounded-top home-ticket"
          style={{ backgroundImage: `url('${lottery_image_url}')` }}
        >
          <div
            className="rounded remove-rounded-top content"
            style={backgroundStyle}
          >
            <div className="background py-2 px-1">
              <div className="d-flex flex-row detail">
                <div
                  style={{ backgroundImage: `url('${lottery_logo_url}')` }}
                  className="col-3 lottery_logo"
                />
                <div className="col-9 col-md-12 px-2 px-md-0 px-lg-1 ">

                  {is_ongoing ?

                    (<div><small className="my-0 h6">{t("home.nextsuperprize")}</small>
                      <h3 className="my-0">
                        {t("const_file.rs")} {jackpot_price}
                      </h3> </div>) : (<div></div>
                    )}

                  <h6 className="my-0 bolded">
                    {t(this.loadLotteryName(lottery_name))}
                  </h6>
                  <small className="my-0">
                    {t("home.draw")} : {draw_number} - {drawTimeLable}
                  </small>
                </div>
              </div>
              <div className="d-flex flex-row pt-2 button-set">
                <div className="col-4 p-1 text-center">
                  {this.renderSubscribeButtons()}
                </div>
                {is_ongoing ? (
                  this.renderBuyButtons()
                ) : (
                    <div className="col-8 p-1 text-center">
                      <button
                        type="button"
                        style={buttonStyle}
                        disabled="disabled"
                        className="btn btn-search btn-block "
                      >
                        <CloseIcon size={16} />
                        <br />
                        {t("home.soldout")}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators({ openDialog, setNextEvent }, dispatch);
}

function mapStateToProps({
  token,
  packages,
  myPackages,
  sharedStatus,
  dialog_app_kp
}) {
  return { token, packages, myPackages, sharedStatus, dialog_app_kp };
}
export default translate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeLottery)
);
