import React, { Component } from 'react';
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { loadResults , loadAvaliableLotteries} from "../actions/index";

import {
    translate,
    getLanguage
} from 'react-switch-lang';

class About extends Component {
    constructor(props) {
        super(props);
    }

   
    render() {
        const { t } = this.props;
        var lan =getLanguage();

        return (
            <div className="innerContent">
              <h2 className="content-title">{t("about.title")}</h2>
              <p>{t("about.title")}</p>
              <p>{t("about.para1")}</p>
              <p>{t("about.para2")}</p>
              <p>{t("about.para3")}</p>
              </div>
        );
    }
}



// function mapStateToProps({ }) {
//     return { };
// }

export default translate(connect()(About));

// export default About;
