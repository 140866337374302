import { LOAD_MY_PACKAGES, PACKAGE_DEACTIVATED, CLEAR_MY_PACKAGES } from "../actions/index";
import _ from "lodash";

export default function (state = [], action) {
    switch (action.type) {
        case LOAD_MY_PACKAGES:
            return action.payload.data;
        case PACKAGE_DEACTIVATED:
            var index = 0;
            for (var i = 0; i < state.length; i++) {
                if (state[i].package_id === action.payload.package_id) {
                    index = i;
                }
            }
            return [...state.slice(0, index), ...state.slice(index + 1)];
        case CLEAR_MY_PACKAGES:
          return [];
        default:
            return state;
    }
}
