import React, { Component } from "react";
import reduxDialog, { closeDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { compose } from "recompose";

import { LOTTERY_LIST } from '../config/';
import _ from "lodash";

import { bindActionCreators } from "redux";

import { randomBuy } from "../actions/index";
import { MODALS, CONVENIENT_FEE } from "../config";
import { translate, getLanguage } from "react-switch-lang";

class BuyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      loadEditText: false
    };
    this.updateInput = this.updateInput.bind(this);
  }

  renderCount(count) {
    const {
      t,
      data: { lottery_price, light_color, dark_color }
    } = this.props;
    var style = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: ""
    };

    if (this.state.count === count) {
      style.color = "#ffffff";
      style.backgroundColor = dark_color;
      style.selected = " selected";
    }

    return (
      <div key={count} className="col-4 p-1">
        {count === 50 ? (
          <button
            className="btn btn-block mr-1"
            style={style}
            onClick={() => this.setState({ loadEditText: true })}
          >
            <h1>+</h1>
            <small>{t("const_file.other")}</small>
          </button>
        ) : (
            <button
              className={"btn btn-block mr-1" + style.selected}
              style={style}
              id="buy_button_id"
              onClick={() => this.setState({ count })}
            >
              <h1>{count}</h1>
              <small>
                {t("const_file.rs")}
                {count * lottery_price}
              </small>
            </button>
          )}
      </div>
    );
  }

  updateInput(event) {
    event.preventDefault();
    // this.setState({ mobile_pin: event.target.value });
    if (event.target.value <= 0) {
      if (event.target.value !== "") {
        this.setState({ count: 1 });
        ReactDOM.findDOMNode(this.refs.ticket_count_buy).value = 1;
      }
    } else {
      this.setState({ count: event.target.value });
    }
  }

  renderEditText() {

    const { t } = this.props;

    return (
      <div className="col-12 p-1">
        {
          <input
            type="number"
            ref="ticket_count_buy"
            min="1"
            className="form-control"
            placeholder={t("buymodal.enter_no_tickets_buy")}
            onChange={this.updateInput}
          />
        }
      </div>
    );
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { 'value': lottery_name });

    return ("lottery_tickets." + pack.key);

  }


  render() {
    console.log(this.props);
    const {
      t,
      randomBuy,
      data: { lottery_name, light_color, dark_color, draw_id }
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff"
    };

    return (
      <div className="p-3">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("buymodal.buy")} {t(this.loadLotteryName(lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.props.closeDialog(MODALS.BUY)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h4>{t("buymodal.no_tickets_buy")}</h4>
          <div className="my-3 px-3 row">
            {this.state.loadEditText
              ? this.renderEditText()
              : [1, 2, 5].map(count => {
                return this.renderCount(count);
              })}
            {this.state.loadEditText
              ? ""
              : [10, 20, 50].map(count => {
                return this.renderCount(count);
              })}
          </div>
          <p className="my-3  text-center">{t("const_file.convenient_fee")}</p>
          <button
            className="btn btn-block mr-1"
            style={buttonStyle}
            onClick={() =>
              randomBuy({ draw_id, lottery_count: this.state.count })
            }
          >
            {t("buymodal.buy")}
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeDialog, randomBuy }, dispatch);
}

export default compose(
  translate,
  reduxDialog({ name: MODALS.BUY, centered: true }),
  connect(
    null,
    mapDispatchToProps
  )
)(BuyModal);
