import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InfiniteScroll from 'react-infinite-scroller';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';

import HomeLottery from './HomeLottery';
import { GOOGLE_ANALYTICS } from '../config/';
import { randomBuy, subscribe, loadOngoingLotteries, setNextEvent, loadLotteryPackages } from "../actions/index";


class Home extends Component {

    componentWillMount() {
        // this.props.loadHeaderMobile();
        // this.props.getLotterySubcribeList();
        this.props.loadOngoingLotteries();
        this.props.loadLotteryPackages();
        if (this.props.match.params.id) {
        }
        // if (this.props.location.search) {
        //     var paramsString = this.props.location.search;
        //     const params = new URLSearchParams(paramsString);
        //     const kpValue = params.get('kp');
        //     const kpData = { "kp": kpValue }
        //     this.props.enterKpValue(kpData);
        // }
        // this.props.openDialog('MODAL_ADVERT');
    }

    renderLotteries() {
        const { ongoingLotteries, match: { params }, randomBuy, subscribe } = this.props;

        if (this.props.location) {
            var paramsString = this.props.location.search;
            const params = new URLSearchParams(paramsString);
            const tags = params.get('kp');
        }

        return ongoingLotteries.data.map(lottery => {
            return (
                <HomeLottery subcribeId={params.id} key={lottery.lottery_id} lottery={lottery} randomBuys={randomBuy} subscribes={subscribe} setNextEvent={setNextEvent}
                />
            );
        });
    }

    render() {
        return (
            <div className="row p-1 home-screen-height">
                {this.renderLotteries()}
            </div>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadOngoingLotteries, randomBuy, subscribe, setNextEvent, loadLotteryPackages, closeDialog, openDialog }, dispatch);
}

function mapStateToProps({ ongoingLotteries }) {
    return { ongoingLotteries };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
