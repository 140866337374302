import React, { Component } from "react";
import { connect } from "react-redux";
import { LOTTERY_LIST } from "../config/";

import _ from "lodash";
import { translate, getLanguage } from "react-switch-lang";

class MyTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  renderTicketFields(fields) {
    return fields.map((field, index) => {
      return field ? (
        <div
          className={
            field.special_number
              ? "px-2 py-1 m-1 ticket-number bg-secondary"
              : "px-2 py-1 m-1 ticket-number bg-white"
          }
          key={index}
        >
          {field.value}
        </div>
      ) : (
        ""
      );
    });
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    return "lottery_tickets." + pack.key;
  }

  render() {
    const {
      lottery_name,
      draw_number,
      draw_date,
      serial_number,
      lottery_number_fields,
      winnig_price,
      puchased_date
    } = this.props.result;
    const { t } = this.props;

    return (
      <div
        key={serial_number}
        className="col-12 col-md-4 col-lg-3 my-1 px-2 py-lg-1"
      >
        <div className="p-2 bg-light rounded h-100">
          <div className="d-flex flex-row p-1 justify-content-between align-items-top">
            <div>
              <h5 className="my-0 bolded">
                {t(this.loadLotteryName(lottery_name))}
              </h5>
              <small className="my-0">
                {t("myaccount.draw_no")}: {draw_number} -{" "}
                {draw_date.substr(0, 10)}
                <br /> {t("myaccount.purchased_date")}: {puchased_date}
              </small>
            </div>
            <div className="mx-1 text-right">
              {winnig_price ? (
                <span className="badge badge-success p-2">
                  Won Rs. {winnig_price}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="bg-light p-1 rounded text-center">
            {this.renderTicketFields(lottery_number_fields)}
          </div>
          <div className="text-center mt-2">
            <small className="my-0">
              {t("myaccount.serial_number")}: {serial_number}
            </small>
          </div>
        </div>
      </div>
    );
  }
}
export default translate(connect()(MyTicket));
