import axios from "axios";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { openDialog, closeDialog } from "redux-reactstrap-modal";

import { API_ROOT_URL, MODALS, EVENTS, LIMIT, SIMATO_AFFID, SIMATO_USERID } from '../config/';
import ReactGA from 'react-ga';
import _ from "lodash";
import generator from 'generate-password';


export const LOAD_ONGOING_LOTTERIES = "ONGOING_LOTTERIES_LOADED";
export const LOAD_PACKAGES = "PACKAGES_LOADED";
export const LOAD_RESULTS = "RESULTS_LOADED";
export const LOAD_WALLET_BALANCE = "WALLET_BALANCE_LOADED";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOAD_USER_ACCOUNT = "USER_ACCOUNT_LOADED";
export const LOAD_MY_TICKETS = "LOAD_MY_TICKETS";
export const LOAD_MY_PACKAGES = "LOAD_MY_PACKAGES";
export const NEXT_EVENT = "NEXT_EVENT";
export const CLEAR_NEXT_EVENT = "CLEAR_NEXT_EVENT";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const PACKAGE_DEACTIVATED = "PACKAGE_DEACTIVATED";
export const REGISTER = 'REGISTER';
export const RESET_USER = 'RESET_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const RESULT_LOADING = 'RESULT_LOADING';
export const AVAILABLE_LOTTERIES_LOADED = 'AVAILABLE_LOTTERIES_LOADED';
export const NIC_ENTER = 'NIC_ENTER';
export const SUBCRIBE_SUCCESS = 'SUBCRIBE_SUCCESS';
export const HEADERMOBILEADDED = 'HEADERMOBILEADDED';
export const SECURE_SUBCRIBE_LIST = 'SECURE_SUBCRIBE_LIST';
export const DIALOG_APP_KP = 'DIALOG_APP_KP';
export const CLEAR_MY_PACKAGES = 'CLEAR_MY_PACKAGES';
export const CLEAR_SECURE_SUBCRIBE_LIST = 'CLEAR_SECURE_SUBCRIBE_LIST';
export const LANGUAGE = 'LANGUAGE';



export function loadLanguage(data) {

   
  return function (dispatch, getState) {
    dispatch({
      type: LANGUAGE,
      payload: data
    });
  }
}


const axios_instance = axios.create({
  baseURL: API_ROOT_URL
});

const axios_dialog_ad = axios.create({
  baseURL: 'http://97.74.195.115:8061/trackingconversion/con'
});

axios_instance.defaults.headers.common['X-API-User-Token'] = localStorage.getItem('TOKEN') === "" || localStorage.getItem('TOKEN') === null ? "" : localStorage.getItem('TOKEN');

export const clearNotification = function () {
  return ({
    type: CLEAR_NOTIFICATION,
    payload: null
  });
}

export const showNotificationModal = function (data) {
  return ({
    type: SHOW_NOTIFICATION_MODAL,
    payload: data
  });
}



export const setNextEvent = function (data) {
  // axios_instance.defaults.headers.common['nic'] = data.nic_number;
  return (dispatch) => {
    dispatch({
      type: NEXT_EVENT,
      payload: { event: data.event, data: data.props }
    });
  };
}

export const clearNextEvent = function (data) {
  return ({
    type: CLEAR_NEXT_EVENT
  });
}

export const enterNIC = function (data) {
  // if(data){
  // axios_instance.defaults.headers.common['nic'] = data.nic_number;
  return (dispatch) => {
    dispatch({
      type: NIC_ENTER,
      payload: data
    });
    dispatch(closeDialog(MODALS.NIC));
  };
}

export const enterKpValue = function (data) {
  // if(data){
  return (dispatch) => {
    dispatch({
      type: DIALOG_APP_KP,
      payload: data
    });
  };
}


export const loadOngoingLotteries = scope => (dispatch) => {
  dispatch(showLoading(scope));
  const url = `/public/lotteries?limit=20&offset=0`;
  axios_instance.get(url).then(({ data }) => {
    dispatch({
      type: LOAD_ONGOING_LOTTERIES,
      payload: data
    });

    const url = `/public/lottery-packages?limit=20&offset=0`;
    axios_instance.get(url).then(({ data }) => {
      dispatch(hideLoading(scope));
      dispatch({
        type: LOAD_PACKAGES,
        payload: data
      });

      const url = `/public/lottery-list`;
      axios_instance.get(url).then(({ data }) => {
        dispatch(hideLoading(scope));
        dispatch({
          type: AVAILABLE_LOTTERIES_LOADED,
          payload: data
        });
      });

    });


  });
}

export const loadAvaliableLotteries = function () {
  const url = `/public/lottery-list`;

  return (dispatch) => {
    axios_instance.get(url).then(({ data }) => {
      dispatch({
        type: AVAILABLE_LOTTERIES_LOADED,
        payload: data
      });
    });
  };

};

export const loadResults = function (offset = 0, filters = {}, reset = false) {
  return (dispatch, getState) => {
    dispatch({ type: RESULT_LOADING, payload: true });

    const state = getState();
    let lottery_id = '';
    let draw_number = '';
    let searchDate = '';
    // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
    var search_lottery = "";


    if (filters && filters.lottery_id) {
      search_lottery = state.available_packages.data.find(item => item.lottery_id == filters.lottery_id);
      lottery_id = filters.lottery_id;
    }

    if (filters && filters.draw_number) {
      draw_number = filters.draw_number;
    }

    if (filters && filters.search_date) {
      searchDate = filters.search_date;
    }


    if (filters && filters.lottery_id === '') {
      lottery_id = '';
    }
    if (filters && filters.lottery_id === 'Select') {
      lottery_id = '';
    }

    if (filters && filters.draw_number !== '' && filters.lottery_id === '') {
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "danger", message: 'Select a lottery ticket' }
      });
    }

    if (filters && filters.draw_number === '') {
      draw_number = '';
    }
    // dispatch(showLoading(scope));

    const url = `/public/lottery-results?limit=${LIMIT}&offset=${offset}&draw_number=${draw_number}&lottery_id=${lottery_id}&draw_date_time=${searchDate}`;
    axios_instance.get(url).then(({ data }) => {
      // dispatch(hideLoading(scope));
      console.log(data);
      let dataset = [];
      const dataInitial = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
      const { results } = state;
      if (data && data.data) {
        if (offset > 0) {

          let datasets = results.data.concat(data.data);
          data.data = datasets;
          dataset = data;
        } else {
          dispatch({
            type: LOAD_RESULTS,
            payload: dataInitial
          });
          dataset = data;
          if (filters) {
            if (filters && filters.lottery_id) {
              ReactGA.event({
                category: "Results",
                action: "View",
                label: search_lottery.lottery_name,
                value: 1
              });
            }
          } else {
            ReactGA.event({
              category: "Results",
              action: "View",
              label: "All",
              value: 1
            });
          }

        }

      }
      dispatch({ type: RESULT_LOADING, payload: false });
      dispatch({
        type: LOAD_RESULTS,
        payload: dataset
      });
    });
  }
}



export const login = function (props) {
  const url = `/public/user/create-exchange-token`;
  const request = axios_instance.post(url, props);

  return (dispatch) => {
    request.then(({ data }) => {
      axios_instance.defaults.headers.common['X-API-User-Token'] = data.data.exchange_token;
      dispatch({
        type: LOGGED_IN,
        payload: data
      });
      dispatch(closeDialog(MODALS.LOGIN));
    }).catch(function (res) {
      if (res instanceof Error) {
        if (props.facebook_id || props.google_id) {
          dispatch({ type: SOCIAL_LOGIN, payload: props });
          dispatch(closeDialog(MODALS.LOGIN));
          props.typeSocial = true;
          dispatch(clearNotification());
          dispatch(openDialog(MODALS.SIGNUP, props))
        } else {
          if (res.response.data.error_code == "400024") {
            dispatch(closeDialog(MODALS.LOGIN));
            dispatch(clearNotification());
            dispatch(openDialog(MODALS.PINVERIFY, props))
          } else {
            dispatch({
              type: SHOW_NOTIFICATION_MODAL,
              payload: { type: "danger", message: res.response.data.message }
            });
          }
        }
      } else {
        console.log(res.data);
      }
    });
  };

}

export const fbLogin = function (response) {
  var fb_user = {
    social_account: "facebook",
    facebook_id: response.id,
    facebook_acc_token: response.accessToken,
    name: response.name
  };

  return login(fb_user);
  //anything else you want to do(save to localStorage)...
}

export const googleLogin = function (googleUser) {
  var id_token = googleUser.getAuthResponse().access_token;
  var googleId = googleUser.getId();
  var googleUser = {
    social_account: "google",
    google_id: googleId,
    google_acc_token: id_token,
    name: ""
  };
  return login(googleUser);
  //anything else you want to do(save to localStorage)...
}

export const createNewUserPin = function (mobile_no) {
  // const url = `${API_ROOT_URL}/public/user/create-new-pin`;
  const url = `${API_ROOT_URL}/public/user/create-new-pin`;

  return (dispatch) => {
    return axios_instance
      .post(url, { mobile_no })
      .then(function (response) {
        if (response.data && response.data.message && response.data.message.message) {
          dispatch({
            type: SHOW_NOTIFICATION_MODAL,
            payload: { type: "success", message: response.data.message.message }
          });
          return {
            status: true,
            message: response.data.message.message
          };
        }
        return { status: true };
      })
      .catch(function (error) {

      });
  };
};


export const registerUser = function (saveUser) {

  saveUser.access_source = 'WEB_APP_USER';
  const url = `${API_ROOT_URL}/public/user/save`;

  let password = generator.generate({
    length: 10,
    numbers: true
  });

  if (saveUser.typeSocial) {
    saveUser.password = password;
    saveUser.confirm_password = password;
  }

  const request = axios_instance.post(url, saveUser);

  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({
        type: REGISTER,
        payload: data.data
      });
      dispatch(closeDialog(MODALS.SIGNUP));
      dispatch(clearNotification());
      dispatch(openDialog(MODALS.PINVERIFY, saveUser));
      // dispatch(closeDialog(MODALS.LOGIN));
    }).catch(error => {
      if (error.response.data.error_code !== null) {
        if (error.response.data.error_code === "400024") {
          dispatch(closeDialog(MODALS.SIGNUP));
          dispatch(clearNotification());
          dispatch(openDialog(MODALS.PINVERIFY, saveUser));
          // dispatch(closeDialog(MODALS.LOGIN));
        }
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
        return {
          status: false,
          message: error.response.data.message
        }
      }
    });
  };
}

export const userNumberVerify = function (mobile_pin, mobile_number) {

  // axios_instance.defaults.headers.common['otp'] = mobile_pin;
  let body = {
    mobile_pin: mobile_pin,
    mobile_no: mobile_number
  };
  const url = `${API_ROOT_URL}/public/user/validate-pin`;

  return (dispatch, getState) => {

    return axios_instance
      .post(url, body)
      .then(function (response) {
        try {
          if (response && response.data) {
            axios_instance.defaults.headers.common['X-API-User-Token'] = response.data.data.exchange_token;
            dispatch({ type: LOGGED_IN, payload: response.data });
            // axios_instance.defaults.headers.common['otp'] = null;
            dispatch(closeDialog(MODALS.PINVERIFY));
            dispatch(closeDialog(MODALS.NIC));
          } else {
          }
        } catch (error) {
          return {
            status: false,
            message: error.response.data.message
          };
        }
      })
      .catch(function (error) {
        // axios_instance.defaults.headers.common['otp'] = null;
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
        return {
          status: false,
          message: error.response.data.message
        };
      });
  };
}

export const saveUserPassword = function (savePassword) {
  const url = `${API_ROOT_URL}/secure/user/reset-password`;
  const request = axios_instance.post(url, savePassword);

  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({
        type: RESET_PASSWORD,
        payload: data
      });
      dispatch(closeDialog(MODALS.PASSWORDRESET));
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
    }).catch(function (error) {
      if (error.error_code === '400024') {
        dispatch(closeDialog(MODALS.PASSWORDRESET));
        dispatch(openDialog(MODALS.PINVERIFY));
      } else {
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
      }
    });
  };

};

export const userPasswordForgot = function (forgetData) {
  const url = `${API_ROOT_URL}/public/user/forgot-password`;
  const request = axios_instance.post(url, forgetData);

  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({
        type: RESET_PASSWORD,
        payload: data
      });
      dispatch(closeDialog(MODALS.PASSWORDFORGET));
      dispatch(openDialog(MODALS.PINVERIFY, forgetData));
    }).catch(function (error) {

      console.log(error);
      if (error.response.data.error_code === '400024') {
        dispatch(closeDialog(MODALS.PASSWORDFORGET));
        dispatch(openDialog(MODALS.PINVERIFY, forgetData));
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
      } else {
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
      }
    });
  };

};

export const logout = function (props) {

  axios_instance.defaults.headers.common['X-API-User-Token'] = "";
  // axios_instance.defaults.headers.common['msisdn'] = "";
  return (dispatch, getState) => {
    dispatch({ type: LOGGED_OUT });
    dispatch({ type: CLEAR_MY_PACKAGES });
    dispatch({ type: CLEAR_SECURE_SUBCRIBE_LIST });
  }
}


export const loadLotteryPackages = scope => (dispatch) => {
  dispatch(showLoading(scope));
  const url = `secure/lottery-packages`;
  axios_instance.get(url).then(({ data }) => {

    dispatch({
      type: LOAD_MY_PACKAGES,
      payload: data
    });
    dispatch(hideLoading(scope));

  }).catch(error => {
    dispatch({
      type: CLEAR_MY_PACKAGES
    });
    dispatch(hideLoading(scope));
    // if (error.response.data.error_code === "401001") {
    //   dispatch(logout());
    // }
  });
}

export const loadMyLottery = function (offset = 0, filters = {}, reset = false) {

  return (dispatch, getState) => {
    // dispatch(showLoading(scope));
    const state = getState();
    let lottery_id = '';
    let draw_number = '';
    let searchDate = '';


    if (filters && filters.lottery_id) {
      lottery_id = filters.lottery_id;
    }

    if (filters && filters.draw_number) {
      draw_number = filters.draw_number;
    }

    if (filters && filters.search_date) {
      searchDate = filters.search_date;
    }


    if (filters && filters.lottery_id === '') {
      lottery_id = '';
    }
    if (filters && filters.lottery_id === 'Select') {
      lottery_id = '';
    }

    if (filters && filters.draw_number !== '' && filters.lottery_id === '') {
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "danger", message: 'Select a lottery ticket' }
      });
    }

    if (filters && filters.draw_number === '') {
      draw_number = '';
    }


    const url = `secure/lottery-tickets?limit=${LIMIT}&offset=${offset}&lottery_id=${lottery_id}&draw_number=${draw_number}&purchased_date=${searchDate}`;
    axios_instance.get(url).then(({ data }) => {

      console.log(data);
      let dataset = [];
      const dataInitial = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
      const { myTickets } = state;
      if (data && data.data) {
        if (data.page.offset > 0) {
          let datasets = myTickets.data.concat(data.data);
          data.data = datasets;
          dataset = data;
        } else {
          dispatch({
            type: LOAD_MY_TICKETS,
            payload: dataInitial
          });
          dataset = data;
        }
      }

      dispatch({
        type: LOAD_MY_TICKETS,
        payload: dataset
      });

    }).catch(error => {

    });
  }
}



export const loadAccount = scope => (dispatch) => {
  dispatch(showLoading(scope));
  const url = `/secure/user/view-mobile-user`;


  axios_instance.get(url).then(({ data }) => {

    dispatch({
      type: LOAD_USER_ACCOUNT,
      payload: data
    });

    const url = `secure/lottery-packages`;
    axios_instance.get(url).then(({ data }) => {

      dispatch({
        type: LOAD_MY_PACKAGES,
        payload: data
      });

      const url = `secure/user/wallet-balance`;
      axios_instance.get(url).then(({ data }) => {

        dispatch({
          type: LOAD_WALLET_BALANCE,
          payload: data
        });

        dispatch(hideLoading(scope));
      });

    });

  }).catch(error => {
    dispatch(hideLoading(scope));
    if (error.response.data.error_code === "401001") {
      dispatch(logout());
      dispatch(openDialog(MODALS.LOGIN));
      dispatch({
        type: NEXT_EVENT,
        payload: { event: EVENTS.ACCOUNT }
      });
    }
  });
}


export const subscribe = (props, scope) => {
  const url = `/secure/lottery-package/subscribe`;
  props.source = "WEB_APP_USER";
  // if (props.mobile_no !== "") {
  //   axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
  // }

  // if(localStorage.getItem('TOKEN') === "")



  const request = axios_instance.post(url, props);

  return scope = (dispatch, getState) => {

    const state = getState();


    var search_lottery = { lottery_name: "" };
    if (state.packages.length !== 0) {
      search_lottery = state.packages.find(item => item.package_name == props.lottery_name);
    }
    dispatch({
      type: CLEAR_NEXT_EVENT
    });

    request.then(({ data }) => {

      ReactGA.event({
        category: "Package",
        action: "Subscribe",
        label: search_lottery.package_name !== "" ? search_lottery.package_name : 'New Lottery',
        value: props.subscribe_count
      });

      dispatch(closeDialog(MODALS.SUBSCRIBE));
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
      dispatch({
        type: SUBCRIBE_SUCCESS,
        payload: { "subcribe_success": true, "id": props.package_id }
      });
      dispatch(loadLotteryPackages());
      // dispatch(getLotterySubcribeList());
      dispatch(closeDialog(MODALS.NIC));
    }).catch(error => {
      dispatch(hideLoading(scope));
      if (error.response.data.error_code === "401001") {
        dispatch(logout());
        dispatch(closeDialog(MODALS.SUBSCRIBE));
        dispatch(openDialog(MODALS.LOGIN));
        dispatch({
          type: NEXT_EVENT,
          payload: { event: EVENTS.SUBSCRIBE, data: props }
        });
      } else {
        dispatch(closeDialog(MODALS.SUBSCRIBE));
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { type: "danger", message: error.response.data.message }
        });
      }
    });
    // this.props.location.query.__firebase_request_key

  };
}


export const randomBuy = (props, scope) => {
  const url = `/secure/lottery-tickets/random-buy`;
  props.purchased_source = "WEB_APP_USER";
  // if (props.mobile_no !== "") {
  //   axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
  // }

  const request = axios_instance.post(url, props);


  return scope = (dispatch, getState) => {
    dispatch({
      type: CLEAR_NEXT_EVENT
    });

    const state = getState();
    // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
    var search_lottery = state.ongoingLotteries.data.find(item => item.draw_id == props.draw_id);


    request.then(({ data }) => {
      dispatch(closeDialog(MODALS.BUY));
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
      dispatch(closeDialog(MODALS.NIC));
      ReactGA.event({
        category: "Ticket",
        action: "Random Buy",
        label: search_lottery.lottery_name,
        value: props.lottery_count
      });

    }).catch(error => {
      dispatch(hideLoading(scope));
      if (error.response.data.error_code === "401001") {
        dispatch(logout());
        dispatch(closeDialog(MODALS.BUY));
        dispatch(openDialog(MODALS.LOGIN));
        dispatch({
          type: NEXT_EVENT,
          payload: { event: EVENTS.RANDOM_BUY, data: props }
        });
      } else {
        dispatch(closeDialog(MODALS.BUY));
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { type: "danger", message: error.response.data.message }
        });
      }
    });;
  };
}

export const buy = (props, scope) => {
  const url = `/secure/lottery-tickets/buy`;
  //props.purchased_source = "WEB_APP_USER";
  // if (props.mobile_no !== "") {
  //   axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
  // }

  const request = axios_instance.post(url, props);


  return scope = (dispatch, getState) => {
    dispatch({
      type: CLEAR_NEXT_EVENT
    });
    const state = getState();
    // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
    var search_lottery = state.ongoingLotteries.data.find(item => item.draw_id == props.draw_id);

    request.then(({ data }) => {

      dispatch(closeDialog(MODALS.NIC));
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
      dispatch({
        type: SHOW_NOTIFICATION_MODAL,
        payload: { type: "success", message: data.message.message }
      });

      ReactGA.event({
        category: "Ticket",
        action: "Search and Buy",
        label: search_lottery.lottery_name,
        value: props.lottery_ticket_ids.length
      });

      dispatch(closeDialog(MODALS.WARNING));
      // dispatch(openDialog(MODALS.NIC));
      // dispatch({
      //   type: NEXT_EVENT,
      //   payload: { event: EVENTS.BUY, data: props }
      // });

    }).catch(error => {
      dispatch(hideLoading(scope));
      if (error.response.data.error_code === "401001") {
        dispatch(logout());
        dispatch(closeDialog(MODALS.WARNING));
        dispatch(closeDialog(MODALS.SEARCH));
        dispatch(openDialog(MODALS.LOGIN));
        dispatch({
          type: NEXT_EVENT,
          payload: { event: EVENTS.BUY, data: props }
        });
      } else {
        dispatch(closeDialog(MODALS.WARNING));
        dispatch(closeDialog(MODALS.SEARCH));
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { type: "danger", message: error.response.data.message }
        });
      }
    });;
  };
}


export const deactivate = (props, scope) => {
  //props.source = "WEB_APP_USER";
  const url = `/secure/lottery-package/unsubscribe`;
  const request = axios_instance.post(url, props);

  return scope = (dispatch, getState) => {
    const state = getState();
    // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);

    var search_lottery = {};
    // var ass=  _.map(state.ongoingLotteries.data, 'draw_id'===props.draw_id);
    if (state.myPackages.length !== 0) {
      search_lottery = state.myPackages.find(item => item.package_id == props.package_id);
    } else {
      search_lottery = { lottery_name: "" };
    }

    request.then(({ data }) => {
      dispatch(closeDialog(MODALS.WARNING));
      dispatch({
        type: PACKAGE_DEACTIVATED,
        payload: props
      });
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
      ReactGA.event({
        category: "Package",
        action: "Unsubscribe",
        label: search_lottery.package_name,
        value: 1
      });
    }).catch(error => {
      dispatch(hideLoading(scope));
      if (error.response.data.error_code === "401001") {
        dispatch(logout());
        dispatch(closeDialog(MODALS.WARNING));
        dispatch(openDialog(MODALS.LOGIN));
      }
    });;
  };


}

export const mobileNoUpdate = (props, scope) => {
  const url = `/secure/create-temp-token`;
  // if (props.mobile_no !== "") {
  //   // axios_instance.defaults.headers.common['msisdn'] = props.mobile_no;
  // }
  if (props.nic_number) {
    // axios_instance.defaults.headers.common['nic'] = props.nic_number;
  }

  const request = axios_instance.post(url, props);


  return scope = (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: HEADERMOBILEADDED,
      payload: { "msisdn": props.mobile_no }
    });

    request.then(({ data }) => {
      dispatch(closeDialog(MODALS.LOGIN));
      dispatch({
        type: SHOW_NOTIFICATION,
        payload: { type: "success", message: data.message.message }
      });
      // dispatch(getLotterySubcribeList());
      dispatch(openDialog(MODALS.PINVERIFY, props));
      dispatch(closeDialog(MODALS.NIC));
      dispatch(clearNotification());
    }).catch(error => {
      dispatch(hideLoading(scope));
      if (error.response.data.error_code === "400004") {
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
        dispatch(closeDialog(MODALS.LOGIN));
        dispatch(openDialog(MODALS.NIC, props));
      } else {
        dispatch({
          type: SHOW_NOTIFICATION_MODAL,
          payload: { type: "danger", message: error.response.data.message }
        });
      }

    });
  };

}

// export const getLotterySubcribeList = (props, scope) => {
//   const url = `/secure/user/lottery-packages`;

//   const request = axios_instance.get(url);
//   return scope = (dispatch, getState) => {
//     const state = getState();
//     request.then(({ data }) => {
//       dispatch({
//         type: SECURE_SUBCRIBE_LIST,
//         payload: data.data
//       });
//     }).catch(error => {

//     });
//   };

// }


