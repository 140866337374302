import { LOAD_MY_TICKETS } from "../actions/index";

export default function (state = { data: [] }, action) {
    switch (action.type) {
        case LOAD_MY_TICKETS:
            return action.payload;
        default:
            return state;
    }
}
