import React, { Component } from "react";
import axios from "axios";
import { LOTTERY_LIST } from '../config/';
import _ from "lodash";
import reduxDialog, { closeDialog, openDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";
import LoadingBar, { showLoading, hideLoading } from "react-redux-loading-bar";

import {
  API_ROOT_URL,
  SEARCH_LIMIT,
  CONVENIENT_FEE,
  MODALS,
  EVENTS
} from "../config/";
import { bindActionCreators } from "redux";
import { translate, getLanguage } from "react-switch-lang";

import { buy } from "../actions/index";
// import InfiniteScroll from 'react-infinite-scroller';
import NewTicket from "../components/NewTicket";
import ReactGA from "react-ga";
// import InfiniteLoader from 'react-infinite-loader'
// import InfiniteScroll from 'react-simple-infinite-scroll';
import Infinite from "react-infinite";

import NotificationBarModal from "./NotificationBarModal";
class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.fields = [];
    this.state = {
      all_tickets: [],
      selected_tickets: [],
      suggestions: false,
      offset: 0,
      hasMore: false,
      buy_all_enable: false,
      initialLoad: true,
      loadResponse: {},
      offsetValue: 4,
      isInfiniteLoading: false
    };
  }

  searchTickets(no_exact_matches = false, offset = 0, selected_tickets = []) {
    const { max_ticket_limit, draw_id } = this.props.data;
    var self = this;
    self.props.showLoading("loading_search");
    var all_empty = _.every(self.fields, a => {
      return !a;
    });
    var buy_enable = false;
    const url = `${API_ROOT_URL}/public/lottery-tickets/search?limit=${
      buy_enable ? 500 : SEARCH_LIMIT
      }&offset=${offset}`;
    axios
      .post(url, {
        draw_id: draw_id,
        fields: self.fields
      })
      .then(function (response) {
        console.log(response.data.data.length);
        if (response.data.data.length > 0) {
          self.state.offsetValue++;
          if (offset > 0) {
            let dataset = [];
            const { all_tickets } = self.state;
            dataset = all_tickets.concat(response.data.data);

            self.setState({
              offsetValue: self.state.offsetValue,
              loadResponse: response.data,
              all_tickets: dataset,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: !(all_empty || self.fields.length === 0),
              initialLoad: false
            });
          } else {
            self.setState({
              offsetValue: self.state.offsetValue,
              loadResponse: response.data,
              all_tickets: response.data.data,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: !(all_empty || self.fields.length === 0),
              initialLoad: false
            });
          }

          self.props.hideLoading("loading_search");

          var search_lottery = self.props.ongoingLotteries.data.find(
            item => item.draw_id == draw_id
          );
          ReactGA.event({
            category: "Ticket",
            action: "Search",
            label: search_lottery.lottery_name,
            value: 1
          });

          // self.setState({ all_tickets: response.data.data, selected_tickets: [], suggestions: false, buy_all_enable: !(all_empty || self.fields.length === 0) });
        } else {
          self.getSuggestions(true);
        }
      })
      .catch(function (error) {
        self.props.hideLoading("loading_search");
        console.log(error);
      });
  }

  getSuggestions(no_exact_matches = true, offset = 0, selected_tickets = []) {
    const { max_ticket_limit, draw_id } = this.props.data;
    var self = this;
    var all_empty = _.every(self.fields, a => {
      return !a;
    });

    var buy_enable = !(all_empty || self.fields.length === 0);
    const url = `${API_ROOT_URL}/public/lottery-tickets/search/suggestions?limit=${
      buy_enable ? 500 : SEARCH_LIMIT
      }&offset=${offset}`;
    axios
      .post(url, {
        draw_id: draw_id,
        fields: this.fields
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          self.state.offsetValue++;
          if (offset > 0) {
            let dataset = [];
            const { all_tickets } = self.state;
            dataset = all_tickets.concat(response.data.data);

            self.setState({
              offsetValue: self.state.offsetValue,
              isInfiniteLoading: false,
              loadResponse: response.data,
              all_tickets: dataset,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: false
            });
          } else {
            self.setState({
              offsetValue: self.state.offsetValue,
              isInfiniteLoading: false,
              loadResponse: response.data,
              all_tickets: response.data.data,
              selected_tickets: selected_tickets,
              suggestions: no_exact_matches,
              buy_all_enable: false
            });
          }

          var search_lottery = self.props.ongoingLotteries.data.find(
            item => item.draw_id == draw_id
          );

          ReactGA.event({
            category: "Ticket",
            action: "Suggestion",
            label: search_lottery.lottery_name,
            value: 1
          });
        }
        self.props.hideLoading("loading_search");
      })
      .catch(function (error) {
        console.log(error);
        self.props.hideLoading("loading_search");
      });
  }
  loadMores() { }

  loadMore() {
    // if(0>this.state.as){
    var hasMore;
    if (this.state.suggestions) {
      var limit = this.state.buy_all_enable ? 500 : SEARCH_LIMIT;
      if (this.state.loadResponse.page.total > limit) {
        if (this.state.offset < this.state.loadResponse.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, isInfiniteLoading: false });
      if (hasMore) {
        this.setState({ offset: this.state.offset + SEARCH_LIMIT });
        setTimeout(function () {
          this.getSuggestions(
            true,
            this.state.offset + SEARCH_LIMIT,
            this.state.selected_tickets
          );
        }, 1000);
      }
    } else {
      var hasMore;
      var limit = this.state.buy_all_enable ? 500 : SEARCH_LIMIT;
      if (this.state.loadResponse.page.total > limit) {
        if (this.state.offset < this.state.loadResponse.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, isInfiniteLoading: false });
      if (hasMore) {
        this.setState({ offset: this.state.offset + SEARCH_LIMIT });
        setTimeout(
          function () {
            this.searchTickets(
              false,
              this.state.offset + SEARCH_LIMIT,
              this.state.selected_tickets
            );
          }.bind(this),
          1000
        );

        // setTimeout(function() { this.setState({position: 1}); }.bind(this), 3000);
      }
    }
    // }
  }

  refresh() { }

  selectTicket(ticket_id) {
    this.setState({
      selected_tickets: [...this.state.selected_tickets, ticket_id]
    });
  }

  deselectTicket(ticket_id) {
    var arrayvar = this.state.selected_tickets.slice();
    var index = arrayvar.indexOf(ticket_id);
    arrayvar.splice(index, 1);
    this.setState({ selected_tickets: arrayvar });
  }

  setSearchValue(index, value) {
    this.fields[index] = value;
  }

  buyAll() {
    const { draw_id, lottery_name } = this.props.data;
    var lottery_ticket_ids = _.map(this.state.all_tickets, "lottery_ticket_id");

    if (this.props.token === "" || this.props.token === null) {
      this.props.openDialog(MODALS.LOGIN);
      // setNextEvent({ event: EVENTS.ACCOUNT});
    } else {
      if (lottery_ticket_ids.length > 5000) {
        const data = {
          title: `Buy all ${lottery_name} tickets`,
          message: `Your will get charged Rs.${20 *
            lottery_ticket_ids.length}.00 + telecom fee + tax`,
          action: EVENTS.BUYALL,
          props: {
            draw_id,
            lottery_ticket_ids,
            mobile_no: "",
            purchased_source: "WEB_APP_USER"
          }
        };

        this.props.openDialog(MODALS.WARNING, data);
      } else {
        this.props.buy({
          draw_id,
          lottery_ticket_ids,
          mobile_no: "",
          purchased_source: "WEB_APP_USER"
        });
        this.setState({ all_tickets: [] });
      }
    }
  }

  buySelected() {
    const { draw_id } = this.props.data;

    // if (this.props.token === "" || this.props.token === null) {
    //     // if (this.props.sharedStatus === null) {

    //     // } else {
    //         // if (this.props.sharedStatus.msisdn !== "") {
    //             // this.props.buy({ draw_id, lottery_ticket_ids: this.state.selected_tickets, mobile_no: this.props.sharedStatus.msisdn });
    //             // for (var i = 0; i < this.state.selected_tickets.length; i++) {
    //             //     var index = this.findIndexInData(this.state.all_tickets, "lottery_ticket_id", this.state.selected_tickets[i]);
    //             //     this.state.all_tickets.splice(index, 1);
    //             // }
    //             // this.setState({ all_tickets: this.state.all_tickets, selected_tickets: [] });
    //         // } else {
    //         //     this.props.openDialog(MODALS.LOGIN);
    //         //     setNextEvent({ event: EVENTS.ACCOUNT});
    //         // }
    //     // }
    //     this.props.openDialog(MODALS.LOGIN);
    //     setNextEvent({ event: EVENTS.ACCOUNT});
    // } else {
    this.props.buy({
      draw_id,
      lottery_ticket_ids: this.state.selected_tickets,
      purchased_source: "WEB_APP_USER"
    });
    for (var i = 0; i < this.state.selected_tickets.length; i++) {
      var index = this.findIndexInData(
        this.state.all_tickets,
        "lottery_ticket_id",
        this.state.selected_tickets[i]
      );
      this.state.all_tickets.splice(index, 1);
    }
    this.setState({
      all_tickets: this.state.all_tickets,
      selected_tickets: []
    });
    // }
  }

  renderSearchResults() {
    var { dark_color } = this.props.data;
    var selected_tickets = this.state.selected_tickets;

    return this.state.all_tickets.map(ticket => {
      var selectedTicket = false;
      if (selected_tickets.length !== 0) {
        var pack = _.find(selected_tickets, function (selected_ticket) {
          return selected_ticket === ticket.lottery_ticket_id;
        });
        if (pack) {
          selectedTicket = true;
        }
      }
      return (
        <NewTicket
          selectedTicket={selectedTicket}
          key={ticket.lottery_ticket_id}
          ticket={ticket}
          color={dark_color}
          onSelect={ticket_id => this.selectTicket(ticket_id)}
          onDeselect={ticket_id => this.deselectTicket(ticket_id)}
        />
      );
    });
  }

  renderOptions(values) {
    var options = [];
    for (var value in values) {
      options.push(<option key={values[value]}>{values[value]}</option>);
    }

    return options.map(option => {
      return option;
    });
  }

  scrolled() {
    console.log("Scrolled");
  }

  findIndexInData(data, property, value) {
    for (var i = 0, l = data.length; i < l; i++) {
      if (data[i].lottery_ticket_id === value) {
        return i;
      }
    }
    return -1;
  }

  elementInfiniteLoad() {
    return <div className="infinite-list-item">Loading...</div>;
  }

  renderFields() {
    const {
      t,
      data: { fields }
    } = this.props;
    return fields.map((field, index) => {
      var element = "";
      if (field.format === "JSON") {
        var values = JSON.parse(field.values);
        element = (
          <select
            className="form-control search-dropdown text-center px-0"
            onChange={e => this.setSearchValue(index, e.target.value)}
          >
            <option>{t("searchmodal.select")}</option>
            {this.renderOptions(values)}
          </select>
        );
      } else if (field.format === "Numeric") {
        element = (
          <input
            type="number"
            className="form-control text-center px-0 search-number"
            onChange={e => this.setSearchValue(index, e.target.value)}
          />
        );
      } else {
        element = (
          <input
            type="text"
            className="form-control text-center px-0"
            onChange={e => this.setSearchValue(index, e.target.value)}
          />
        );
      }

      return (
        <div
          className="form-group text-center searchfeild"
          key={`field_${index}`}
        >
          <label className={"mb-0 small"}>
            {field.special_field ? t("searchmodal.special") : field.values}
          </label>
          {element}
        </div>
      );
    });
  }



  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { 'value': lottery_name });

    return ("lottery_tickets." + pack.key);

  }




  render() {
    const {
      t,
      data: { lottery_name, dark_color, max_ticket_limit, lottery_price }
    } = this.props;

    // const {
    //   lottery_name,
    //   dark_color,
    //   max_ticket_limit,
    //   lottery_price
    // } = this.props.data;

    // this.state.offsetValue;
    var edgeoffset =
      (this.state.all_tickets.length - this.state.offset) *
      this.state.offsetValue;
    edgeoffset = edgeoffset + this.state.offsetValue;
    return (
      <div className="p-2 modal_window">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("searchmodal.search")} {t(this.loadLotteryName(lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.props.closeDialog("MODAL_SEARCH")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <LoadingBar
          scope="loading_search"
          style={{ backgroundColor: dark_color, width: "96%" }}
        />
        <div className="modal-body">
          <div className="d-flex flex-row">{this.renderFields()}</div>

          <button
            type="submit"
            onClick={() => this.searchTickets()}
            className="btn btn-block"
            id="search_button_id"
            style={{
              background: dark_color,
              borderColor: dark_color,
              color: "#ffffff"
            }}
          >
            {t("searchmodal.search")}
          </button>
          {this.state.suggestions ? (
            <p className="mt-3">{t("searchmodal.not_match")}</p>
          ) : (
              ""
            )}
          {this.state.all_tickets.length ? (
            <div className="new_scroll">
              {/* <InfiniteScroll
                                    pullDownToRefresh
                                    dataLength={this.state.all_tickets.length} //This is important field to render the next data
                                    pullDownToRefreshContent={
                                        <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                                    }
                                    onScroll={()=>{
                                        console.log('scrollred')
                                    }}
                                    releaseToRefreshContent={
                                        <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                                    }
                                    loadMore={this.loadMore()}
                                    refreshFunction={this.refresh.bind(this)}
                                    hasMore={this.state.hasMore}
                                    loader={<h4 style={{ textAlign: 'center', color: 'white' }}>Loading...</h4>}
                                    endMessage={
                                        <p style={{ textAlign: 'center', color: 'white' }}>
                                        <b>Yay! You have seen it all</b>
                                        </p>
                                    }>
                                    <div className="my-3 search-results" >
                                        {this.renderSearchResults()}
                                    </div>
                                </InfiniteScroll> */}
              <Infinite
                elementHeight={50}
                containerHeight={250}
                infiniteLoadBeginEdgeOffset={edgeoffset}
                onInfiniteLoad={this.loadMore.bind(this)}
                loadingSpinnerDelegate={this.elementInfiniteLoad()}
                isInfiniteLoading={this.state.isInfiniteLoading}
              >
                {this.renderSearchResults()}
              </Infinite>
            </div>
          ) : (
              ""
            )}
          {this.state.all_tickets.length ? (
            this.state.buy_all_enable ? (
              <div>
                <p className="my-3  text-center">
                  {t("const_file.convenient_fee")}
                </p>
                <div className="row">
                  <div className="col-6 pr-1">
                    <button
                      className="btn btn-block"
                      style={{
                        background: dark_color,
                        borderColor: dark_color,
                        color: "#ffffff"
                      }}
                      onClick={() => this.buySelected()}
                    >
                      {t("searchmodal.buy_selected")} (
                      {this.state.selected_tickets.length}) <br />
                      <small>
                        {" "}
                        {t("const_file.rs")}{" "}
                        {this.state.selected_tickets.length * lottery_price}{" "}
                      </small>
                    </button>
                  </div>
                  <div className="col-6 pl-1">
                    <button
                      className="btn btn-block"
                      style={{
                        background: dark_color,
                        borderColor: dark_color,
                        color: "#ffffff"
                      }}
                      onClick={() => this.buyAll()}
                    >
                      {t("searchmodal.buy_all")} (
                      {this.state.all_tickets.length}) <br />
                      <small>
                        {" "}
                        {t("const_file.rs")}{" "}
                        {this.state.all_tickets.length * lottery_price}{" "}
                      </small>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
                <div>
                  <p className="my-3  text-center">
                    {t("const_file.convenient_fee")}
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-block"
                        style={{
                          background: dark_color,
                          borderColor: dark_color,
                          color: "#ffffff"
                        }}
                        onClick={() => this.buySelected()}
                      >
                        {t("searchmodal.buy_selected")} (
                      {this.state.selected_tickets.length}) <br />
                        <small>
                          {" "}
                          {t("const_file.rs")}{" "}
                          {this.state.selected_tickets.length * lottery_price}{" "}
                        </small>
                      </button>
                    </div>
                  </div>
                </div>
              )
          ) : (
              ""
            )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { buy, closeDialog, showLoading, hideLoading, openDialog },
    dispatch
  );
}
function mapStateToProps(state, ownProps) {
  return state;
}

export default compose(
  translate,
  reduxDialog({ name: "MODAL_SEARCH", centered: true, size: "lg" }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchModal);
