import React, { Component } from 'react';


class HowItWorkTml extends Component{

    render (){
        return (<div>
            <p>&nbsp;</p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>&nbsp;</td>
            <td>
            <p><span >குறிச்சொல்</span></p>
            </td>
            <td>
            <p><span >செயல்படுத்துதல்</span></p>
            </td>
            <td>
            <p><strong>செயலிழக்கச்செய்தல்</strong></p>
            </td>
            <td>
            <p><span >சீட்டிழுப்பு</span> <span >தினங்கள்</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மஹஜன</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >MS</span></p>
            </td>
            <td>
            <p><span >MS NIC</span></p>
            </td>
            <td>
            <p><span >MS OFF</span></p>
            </td>
            <td>
            <p><span >திங்கள்</span><span >, </span><span >செவ்வாய்</span><span >, </span><span >வியாழன்</span><span >, </span><span >வெள்ளி</span><span >, <span>சனி</span>, </span><span >ஞாயிறு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>நீரோகா</strong></p>
            </td>
            <td>
            <p><span >NR</span></p>
            </td>
            <td>
            <p><span >NR NIC</span></p>
            </td>
            <td>
            <p><span >NR OFF</span></p>
            </td>
            <td>
            <p><span >செவ்வாய்</span><span >, </span><span >வியாழன்</span><span >, </span><span >ஞாயிறு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மெகா</strong> <strong>பவர்</strong></p>
            </td>
            <td>
            <p><span >MP</span></p>
            </td>
            <td>
            <p><span >MP NIC</span></p>
            </td>
            <td>
            <p><span >MP OFF</span></p>
            </td>
            <td>
            <p><span >திங்கள்</span><span >, </span><span >செவ்வாய்</span><span >, </span><span >புதன்</span><span >, </span><span >வெள்ளி</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>கொவி</strong> <strong>செத</strong></p>
            </td>
            <td>
            <p><span >GS</span></p>
            </td>
            <td>
            <p><span >GS NIC</span></p>
            </td>
            <td>
            <p><span >GS OFF</span></p>
            </td>
            <td>
            <p><span >திங்கள்</span> <span >செவ்வாய்</span> <span >புதன்</span> <span >வியாழன்</span> <span >வெள்ளி</span> <span >சனி</span> <span >ஞாயிறு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>சுப்பிரி</strong> <strong>வாசனா</strong></p>
            </td>
            <td>
            <p><span >SV</span></p>
            </td>
            <td>
            <p><span >SV NIC</span></p>
            </td>
            <td>
            <p><span >SV OFF</span></p>
            </td>
            <td>
            <p><span >திங்கள்</span><span >, </span><span >சனி</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>ஜாதிக</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >JS</span></p>
            </td>
            <td>
            <p><span >JS NIC</span></p>
            </td>
            <td>
            <p><span >JS OFF</span></p>
            </td>
            <td>
            <p><span >புதன்</span><span >, </span><span >சனிக்கிழமை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>வாசனா</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >VS</span></p>
            </td>
            <td>
            <p><span >VS NIC</span></p>
            </td>
            <td>
            <p><span >VS OFF</span></p>
            </td>
            <td>
            <p><span >வியாழக்கிழமை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>செவன</strong></p>
            </td>
            <td>
            <p><span >SEV</span></p>
            </td>
            <td>
            <p><span >SE NIC</span></p>
            </td>
            <td>
            <p><span >SEV OFF</span></p>
            </td>
            <td>
            <p><span >செவ்வாய்</span><span >, </span><span >சனி</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>தன</strong> <strong>நிதானய</strong></p>
            </td>
            <td>
            <p><span >DN</span></p>
            </td>
            <td>
            <p><span >DN NIC</span></p>
            </td>
            <td>
            <p><span >DN OFF</span></p>
            </td>
            <td>
            <p><span >ஞாயிறு</span><span >, </span><span >புதன்</span><span >, </span><span >வெள்ளி</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            <p><span >குறிப்பு: சந்தாதாரர் ஒவ்வொரு லொத்தர் சீட்டிழுப்பின் போதும், ஒரு டிக்கெட்டை பெறுவார். சீட்டிழுப்பின் டிக்கெட் ஒவ்வொன்றிர்க்கும் ரூ .20 + சேவைவழங்குநர் கட்டணம் + வரிகள் வசூலிக்கப்படும்</span></p>
            <p><span >உடனடி</span> <span >கோரிக்கை</span></p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>&nbsp;</td>
            <td>
            <p><span >குறிச்சொல்</span></p>
            </td>
            <td>
            <p><span >செயல்படுத்துதல்</span></p>
            </td>
            <td>
            <p><span >டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மஹஜன</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >OMS</span></p>
            </td>
            <td>
            <p><span >OMS No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>நீரோகா</strong></p>
            </td>
            <td>
            <p><span >ONR</span></p>
            </td>
            <td>
            <p><span >ONR No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மெகா</strong> <strong>பவர்</strong></p>
            </td>
            <td>
            <p><span >OMP</span></p>
            </td>
            <td>
            <p><span >OMP No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>கொவி</strong> <strong>செத</strong></p>
            </td>
            <td>
            <p><span >OGS</span></p>
            </td>
            <td>
            <p><span >OGS No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>சுப்பிரி</strong> <strong>வாசனா</strong></p>
            </td>
            <td>
            <p><span >OSV</span></p>
            </td>
            <td>
            <p><span >OSV No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>ஜாதிக</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >OJS</span></p>
            </td>
            <td>
            <p><span >OJS No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>வாசனா</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >OVS</span></p>
            </td>
            <td>
            <p><span >OVS No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>செவன</strong></p>
            </td>
            <td>
            <p><span >OSEV</span></p>
            </td>
            <td>
            <p><span >OSEV No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>தன</strong> <strong>நிதானய</strong></p>
            </td>
            <td>
            <p><span >ODN</span></p>
            </td>
            <td>
            <p><span >ODN No of tickets NIC</span></p>
            </td>
            <td>
            <p><span >உடனடி கோரிக்கை மூலம் கிடைக்கும் டிக்கெட்களின் எண்ணிக்கை</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            <p><span >குறிப்பு: கோரிக்கைக்கு ஏற்ப உடனடி சீட்டிழுப்பில் இருந்து பெறக்கூடிய &nbsp;டிக்கெட்களின் எண்ணிக்கை</span></p>
            <p>&nbsp;</p>
            <p><span >பெறக்கூடிய லாட்டரிகள் மற்றும் சீட்டிழுப்பு தினங்கள்</span></p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>
            <p><span >லொத்தர் </span><span >உருப்படி</span></p>
            </td>
            <td>
            <p><span >திங்கள்</span></p>
            </td>
            <td>
            <p><span >செவ்வாய்</span></p>
            </td>
            <td>
            <p><span >புதன்</span></p>
            </td>
            <td>
            <p><span >வியாழன்</span></p>
            </td>
            <td>
            <p><span >வெள்ளி</span></p>
            </td>
            <td>
            <p><span >சனி</span></p>
            </td>
            <td>
            <p><span >ஞாயிறு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மஹஜன</strong> <strong>சம்பத</strong></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>உண்டு</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>நீரோகா</strong></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>மெகா</strong> <strong>பவர்</strong></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><strong>கொவி</strong> <strong>செத</strong></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><strong>சுப்பிரி</strong> <strong>வாசனா</strong></p>
            </td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><strong>ஜாதிக</strong> <strong>சம்பத</strong></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><strong>வாசனா</strong> <strong>சம்பத</strong></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><strong>செவன</strong></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><strong>தன</strong> <strong>நிதானய</strong></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >உண்டு</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            </div>);
    }
};

export default HowItWorkTml;
