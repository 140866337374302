import React, { Component } from 'react';
import { Gift } from 'react-feather';
import _ from "lodash";

import { connect } from "react-redux";
import { LOTTERY_LIST } from '../config/';

import {
    translate,
    getLanguage
  } from 'react-switch-lang';

class ResultTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: false
        }
    }

    selectTicket(ticket_id) {
        const { onSelect, onDeselect } = this.props;
        this.setState({ selected: !this.state.selected });
        if (this.state.selected) {
            onDeselect(ticket_id);
        } else {
            onSelect(ticket_id);
        }
    }

    renderTicketFields(fields) {
        const { dark_color } = this.props.result;

        return fields.map((field, index) => {
            var style = {
                background: "#ffffff",
                display: "inline-block",
                border: `1px solid ${dark_color}`,
                color: "#000000"
            };

            if (field.bonus_number) {
                style.color = dark_color;
            }

            if (field.special_number) {
                style.background = dark_color;
                style.color = "#ffffff";
            }

            return <div className="px-2 py-1 m-1 ticket-number" style={style} key={index}>{field.values}</div>
        });
    }

    loadLotteryName(lottery_name){
        const { t } = this.props;
        var pack = _.find(LOTTERY_LIST, { 'value': lottery_name });
    
        return ("lottery_tickets."+pack.key);
        
    }

    render() {
        const { lottery_name, draw_number, dark_color, draw_date_time, light_color, draw_id, fields, lottery_logo_url,
                jackpot_won } = this.props.result;

        const { t } = this.props;

        var backgroundStyle = {
            background: `linear-gradient(${dark_color}, ${light_color})`,
            border: `1px solid ${light_color}`,
            color: "#ffffff"
        }
        var backgroundStyleSupperPrize = {
            background: `${dark_color}`,
            border: `1px solid ${light_color}`,
            color: "#ffffff"
        }
        return (
            <div key={draw_id} className="col-12 col-md-4 col-lg-3 my-1 mt-3 my-lg-3 px-1 px-lg-3">
                <div className="p-2 pt-3 text-center bg-light rounded h-100 position-relative" style={backgroundStyle}>
                    {jackpot_won ?<div className="supperPrize" style={backgroundStyleSupperPrize}><Gift size={12} onClick={() => this.toggleFilter()} /><span>Super Prize Won</span></div>:""}
                    <div className="d-flex flex-row detail">
                        <div style={{ backgroundImage: `url('${lottery_logo_url}')` }} className="col-2 lottery_logo">
                        </div>
                        <div className="col-10 col-lg-12 px-2 text-left">
                            <h5 className="my-0 bolded">{t(this.loadLotteryName(lottery_name))}</h5>
                            <small className="my-0">{t("menu.draw_no")}: {draw_number} - {draw_date_time.substr(0, 10)}</small>
                        </div>
                    </div>
                    <div className="bg-light mt-2 p-1 rounded">
                        {this.renderTicketFields(fields)}
                    </div>
                </div>
            </div>
        );
    }
}
export default translate(connect()(ResultTicket));
