import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import reduxDialog, { closeDialog, openDialog } from "redux-reactstrap-modal";
import { API_ROOT_URL, MODALS, EVENTS } from "../config/";
import { translate, getLanguage } from "react-switch-lang";
import { login, clearNotification } from "../actions/index";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToPasswordForget = this.goToPasswordForget.bind(this);
  }

  goToPasswordForget() {
    this.props.clearNotification();
    this.props.closeDialog(MODALS.LOGIN);

    this.props.openDialog(MODALS.PASSWORDFORGET);
  }

  renderField(field) {
    const {
      meta: { touched, error }
    } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;
    var no_input_button = "";

    if (field.input.name === "mobile_no") {
      no_input_button = "form-control no_input_button";
    } else {
      no_input_button = "form-control";
    }

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className={no_input_button}
          type={field.type || "text"}
          {...field.input}
        />
        <div className="text-help">{touched ? error : ""}</div>
      </div>
    );
  }

  onSubmit(values) {
    this.props.login(values);
  }

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form onSubmit={handleSubmit(this.props.login)}>
        <Field
          label={t("loginmodal.mobile_no")}
          name="mobile_no"
          component={this.renderField}
        />
        <Field
          label={t("loginmodal.password")}
          name="password"
          component={this.renderField}
          type="password"
        />
        <button type="submit" className="btn btn-primary btn-block">
          {t("loginmodal.login")}
        </button>
        <div className="text-center mt-2">
          <a href="#" onClick={this.goToPasswordForget.bind(this)}>
            {t("loginmodal.forgot_password")}
          </a>
        </div>
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { t } = props;

  // Validate the inputs from 'values'
  if (!values.mobile_no) {
    errors.mobile_no = t("validate.mobile_no");
  }
  if (!values.password) {
    errors.password = t("validate.password");
  }
  return errors;
}

export default translate(
  reduxForm({
    validate,
    form: "LoginForm"
  })(
    connect(
      null,
      { login, closeDialog, openDialog, clearNotification }
    )(LoginForm)
  )
);
