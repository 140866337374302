import React, { Component } from 'react';


class HowItWorkSin extends Component{

    render (){
        return (<div>
            <p>&nbsp;</p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>&nbsp;</td>
            <td>
            <p><span >කේතය</span></p>
            <p><span >(Keyword)</span></p>
            </td>
            <td>
            <p><span >සක්&zwj;රීය කිරීම සඳහා</span></p>
            </td>
            <td>
            <p><span >අක්&zwj;රීය කිරීම සඳහා</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදින දිනයන්</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මහජන සම්පත </span></p>
            </td>
            <td>
            <p><span >MS</span></p>
            </td>
            <td>
            <p><span >MS NIC</span></p>
            </td>
            <td>
            <p><span >MS OFF</span></p>
            </td>
            <td>
            <p><span >සඳුදා,අඟහරුවාදා,බ්&zwj;රහස්පතින්දා,සිකුරාදා,සෙනසුරාදා,ඉරිදා </span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >නීරෝගා </span></p>
            </td>
            <td>
            <p><span >NR</span></p>
            </td>
            <td>
            <p><span >NR NIC</span></p>
            </td>
            <td>
            <p><span >NR OFF</span></p>
            </td>
            <td>
            <p><span >අඟහරුවාදා,බ්&zwj;රහස්පතින්දා,ඉරිදා</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මෙගා පවර් </span></p>
            </td>
            <td>
            <p><span >MP</span></p>
            </td>
            <td>
            <p><span >MP NIC</span></p>
            </td>
            <td>
            <p><span >MP OFF</span></p>
            </td>
            <td>
            <p><span >සඳුදා,අඟහරුවාදා,බදාදා,සිකුරාදා </span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ගොවි සෙත </span></p>
            </td>
            <td>
            <p><span >GS</span></p>
            </td>
            <td>
            <p><span >GS NIC</span></p>
            </td>
            <td>
            <p><span >GS OFF</span></p>
            </td>
            <td>
            <p><span >සඳුදා,අඟහරුවාදා,බදාදා,බ්&zwj;රහස්පතින්දා,සිකුරාදා,සෙනසුරාදා,ඉරිදා</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >සුපිරි වාසනා </span></p>
            </td>
            <td>
            <p><span >SV</span></p>
            </td>
            <td>
            <p><span >SV NIC</span></p>
            </td>
            <td>
            <p><span >SV OFF</span></p>
            </td>
            <td>
            <p><span >සඳුදා,සෙනසුරාදා </span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ජාතික සම්පත </span></p>
            </td>
            <td>
            <p><span >JS</span></p>
            </td>
            <td>
            <p><span >JS NIC</span></p>
            </td>
            <td>
            <p><span >JS OFF</span></p>
            </td>
            <td>
            <p><span >බදාදා,සෙනසුරාදා </span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >වාසනා සම්පත </span></p>
            </td>
            <td>
            <p><span >VS</span></p>
            </td>
            <td>
            <p><span >VS NIC</span></p>
            </td>
            <td>
            <p><span >VS OFF</span></p>
            </td>
            <td>
            <p><span >බ්&zwj;රහස්පතින්දා</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >සෙවණ </span></p>
            </td>
            <td>
            <p><span >SEV</span></p>
            </td>
            <td>
            <p><span >SE NIC</span></p>
            </td>
            <td>
            <p><span >SEV OFF</span></p>
            </td>
            <td>
            <p><span >අඟහරුවාදා,සෙනසුරාදා </span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ධන නිධානය </span></p>
            </td>
            <td>
            <p><span >DN</span></p>
            </td>
            <td>
            <p><span >DN NIC</span></p>
            </td>
            <td>
            <p><span >DN OFF</span></p>
            </td>
            <td>
            <p><span >ඉරිදා,බදාදා, සිකුරාදා</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
                    <p><span >සේවා සක්&zwj;රීය කරනු ලබන පාරිභෝගිකයින්ට සෑම ලොතරැයි දිනුම් ඇදීමකදීම එක් ලොතරැයිපතක් හිමිවේ. එක් ලොතරැයිපතක් සඳහා රු.20+ක්&zwj;රියාකරවීමේ ගාස්තු+ </span><span >අදාළ</span><span > බදු අයවේ.</span></p>
            <p>&nbsp;</p>
            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>&nbsp;</td>
            <td>
            <p><span >කේතය</span><span > (Keyword)</span></p>
            </td>
            <td>
            <p><span >සක්&zwj;රීය කිරීම සඳහා</span></p>
            </td>
            <td>
            <p><span >ලොතරැයිපත් සංඛ්&zwj;යාව</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මහජන සම්පත</span></p>
            </td>
            <td>
            <p><span >OMS</span></p>
            </td>
            <td>
            <p><span >OMS ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >නීරෝගා</span></p>
            </td>
            <td>
            <p><span >ONR</span></p>
            </td>
            <td>
            <p><span >ONR ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මෙගා පවර්</span></p>
            </td>
            <td>
            <p><span >OMP</span></p>
            </td>
            <td>
            <p><span >OMP ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ගොවි සෙත</span></p>
            </td>
            <td>
            <p><span >OGS</span></p>
            </td>
            <td>
            <p><span >OGS ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >සුපිරි වාසනා</span></p>
            </td>
            <td>
            <p><span >OSV</span></p>
            </td>
            <td>
            <p><span >OSV ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ජාතික සම්පත</span></p>
            </td>
            <td>
            <p><span >OJS</span></p>
            </td>
            <td>
            <p><span >OJS ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >වාසනා සම්පත</span></p>
            </td>
            <td>
            <p><span >OVS</span></p>
            </td>
            <td>
            <p><span >OVS ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >සෙවණ</span></p>
            </td>
            <td>
            <p><span >OSE</span></p>
            </td>
            <td>
            <p><span >OSE ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >ධන නිධානය</span></p>
            </td>
            <td>
            <p><span >ODN</span></p>
            </td>
            <td>
            <p><span >ODN ටිකට්පත් සංඛ්&zwj;යාව ජා.හැ.අ.</span></p>
            </td>
            <td>
            <p><span >ක්ෂණික දිනුම් ඇදීම් වාරයේ ලොතරැයිපත් ගණන</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
          <p><span >සටහන: </span><span >අද</span> <span >ක්ෂණික</span> <span >දිනුම්</span> <span >ඇදීම්</span> <span >වාරයෙන්</span> <span >ඉල්ලුම්කල</span> <span >ලොතරැයිපත්</span> <span >ගණන</span></p>
            <p>&nbsp;</p>
            <p><strong>ලොතරැයිපත් සහ දිනුම් අදින දිනයන් </strong></p>

            <div className="table-responsive">
            <table>
            <tbody>
            <tr>
            <td>
            <p><span >ලොතරැයිය</span></p>
            </td>
            <td>
            <p><span >සඳුදා</span></p>
            </td>
            <td>
            <p><span >අඟහරුවාදා</span></p>
            </td>
            <td>
            <p><span >බදාදා</span></p>
            </td>
            <td>
            <p><span >බ්&zwj;රහස්පතින්දා</span></p>
            </td>
            <td>
            <p><span >සිකුරාදා</span></p>
            </td>
            <td>
            <p><span >සෙනසුරාදා</span></p>
            </td>
            <td>
            <p><span >ඉරිදා</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මහජන සම්පත</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >නීරෝගා</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >මෙගා පවර්</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >ගොවි සෙත</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            </tr>
            <tr>
            <td>
            <p><span >සුපිරි වාසනා</span></p>
            </td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >ජාතික සම්පත</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >වාසනා සම්පත</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >සෙවණ</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            <p><span >ධන නිධානය</span></p>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            <td>&nbsp;</td>
            <td>
            <p><span >දිනුම් අදියි</span></p>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            </div>);
    }
};


export default HowItWorkSin;


