import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS , GOOGLE_SIGN_IN ,FACEBOOK_SIGN_IN} from '../config/';
import NotificationBarModal from './NotificationBarModal';
import img from '../images/popupSH.png';

class AdvertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillMount(){
        this.props.openDialog('MODAL_ADVERT');
    }

    render() {
        console.log(this.props);
        return (
            <div className="p-0">
                {/* <div className="modal-header">
                    
                    <button type="button" className="close" onClick={() => 
                        {   
                            this.props.closeDialog('MODAL_ADVERT');
                        }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> */}
                <div className="modal-body p-0">
                    <button type="button" className="close advert-close" onClick={() => 
                        {   
                            this.props.closeDialog('MODAL_ADVERT');
                        }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <img src={img} className="img-fluid" />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog, openDialog}, dispatch);
}

export default compose(reduxDialog({ name: 'MODAL_ADVERT', className: 'advert-modal', centered: true }), connect(null, mapDispatchToProps))(AdvertModal);
