import { combineReducers, applyMiddleware, createStore} from "redux";
import ongoingLotteries from "./ongoing_lotteries";
import packages from "./packages";
import results from "./results";
import user from "./user";
import token from "./token";
import myPackages from "./my_packages";
import myTickets from "./my_tickets";
import nextEvent from "./next_event";
import notifications from "./notifications";
import wallet from "./wallet";
import notificationsmodal from "./notificationsmodal";
import result_loading from "./result_loading";
import available_packages from "./available_packages";
import nic_update from "./nic_update";
import sharedStatus from "./shared_status";
// import subcribe_list from "./subcribe_list";
import dialog_app_kp from "./dialog_app_kp";
import language from "./language";


import { reducer as form } from "redux-form";
import { loadingBarReducer } from 'react-redux-loading-bar';
import { dialogReducer } from 'redux-reactstrap-modal';


const rootReducer = combineReducers({
    ongoingLotteries,
    token,
    user,
    dialogReducer,
    results,
    form,
    loadingBar: loadingBarReducer,
    myPackages,
    myTickets,
    nextEvent,
    notifications,
    packages,
    wallet,
    notificationsmodal,
    result_loading,
    available_packages,
    nic_update,
    sharedStatus,
    dialog_app_kp,
    language
});



export default rootReducer;
