import React, { Component } from 'react';
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { Check as CheckIcon } from 'react-feather';
import { loadResults, loadAvaliableLotteries } from "../actions/index";
import { Table } from 'reactstrap';
import TermEn from "../config/language_contend/termen.js";
import TermSin from "../config/language_contend/termsin.js";
import TermTml from "../config/language_contend/termtml.js";

import {
    translate,
    getLanguage
} from 'react-switch-lang';

class Terms extends Component {

    

    render() {

        const { t } = this.props;
        var lan =getLanguage();

        return (
            <div className="innerContent termsPage">
                {lan==='en'?<TermEn/>
                :<span/>}
                {lan==='sin'?<TermSin/>
                :<span/>}
                {lan==='tml'?<TermTml/>
                :<span/>}      
            </div>
        );
    }
}

export default translate(connect()(Terms));
