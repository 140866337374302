import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { enterNIC, mobileNoUpdate, showNotificationModal } from "../actions/index";

class NICModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nic: ""
        }

        this.updateInput = this.updateInput.bind(this);
    }






    updateInput(event) {
        this.setState({ nic: event.target.value });
    }

    userNICSave() {
        const { event, data } = this.props.nextEvent;
        console.log(this.props);
        if (this.state.nic !== "") {
            
                data.nic_number = this.state.nic;
                this.props.enterNIC(data);
            
        } else {
            var dataModal = { type: 'danger', message: 'Enter NIC' }
            this.props.showNotificationModal(dataModal);
        }


    }

    render() {
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">Enter NIC</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.NIC)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />

                    <input type="text" className="form-control" placeholder="Enter NIC" onChange={this.updateInput}></input>

                    <div className="pt-3"><button type="submit" className="btn btn-primary btn-block" onClick={() => this.userNICSave()}>Proceed</button></div>



                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog, openDialog, enterNIC, mobileNoUpdate, showNotificationModal }, dispatch);
}

export default compose(reduxDialog({ name: 'MODAL_NIC', centered: true }), connect(mapStateToProps, mapDispatchToProps))(NICModal);
