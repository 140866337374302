import { LANGUAGE} from "../actions/index";
import Localstore from 'store';
export default function (state = null, action) {
    switch (action.type) {
        case LANGUAGE:
            localStorage.setItem('language-loaded', action.payload);        
            return localStorage.getItem('language-loaded');

        default:
            // localStorage.clear();
            return localStorage.getItem('language-loaded');
    }
}
