import { SUBCRIBE_SUCCESS, HEADERMOBILEADDED } from "../actions/index";

export default function (state = null, action) {
    switch (action.type) {
        case SUBCRIBE_SUCCESS:
            return action.payload;
        case HEADERMOBILEADDED:
            return action.payload;
        default:
            return state;
    }
}
