import { LOAD_ONGOING_LOTTERIES } from "../actions/index";

const data = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
export default function (state = data , action) {
    switch (action.type) {
        case LOAD_ONGOING_LOTTERIES:
            return action.payload;
        default:
            return state;
    }
}
