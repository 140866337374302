import { NEXT_EVENT, CLEAR_NEXT_EVENT } from "../actions/index";

export default function (state = {}, action) {
    switch (action.type) {
        case NEXT_EVENT:
            return action.payload;
        case CLEAR_NEXT_EVENT:
            return {};
        default:
            return state;
    }
}
