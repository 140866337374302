import React, { Component } from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { saveUserPassword } from "../actions/index";

class PasswordResetForm extends Component {

    renderField(field) {

        const { meta: { touched, error } } = field;
        const className = `form-group ${touched && error ? "has-danger" : ""}`;

        return (
            <div className={className}>
                <label>{field.label}</label>
                <input className="form-control" type={field.type || "text"} {...field.input} />
                <div className="text-help">
                    {touched ? error : ""}
                </div>
            </div>
        );
    }

    onSubmit(values) {
        this.props.saveUserPassword(values);
    }

    render() {
        const { handleSubmit } = this.props;

        console.log(this.props);
        return (
            <form onSubmit={handleSubmit(this.props.saveUserPassword)}>
                <Field
                    label="Current Password"
                    name="current_password"
                    component={this.renderField}
                    type="password"
                />
                <Field
                    label="New Password"
                    name="new_password"
                    component={this.renderField}
                    type="password"
                />
                <Field
                    label="Confirm Password"
                    name="confirm_password"
                    component={this.renderField}
                    type="password"
                />
                <button type="submit" className="btn btn-primary btn-block">Send</button>
            </form>
        );
    }
}

function validate(values) {
    const errors = {};

    // Validate the inputs from 'values'
    if (!values.current_password) {
        errors.current_password = "Enter a Old Password";
    }
    if (!values.new_password) {
        errors.new_password = "Enter the New Password";
    }
    if (!values.confirm_password) {
        errors.confirm_password = "Enter the Confirm Password";
    }
    if (!values.new_password===values.confirm_password) {
        errors.confirm_password = "Password does not match";
    }
    return errors;
}

export default reduxForm({
    validate,
    form: "PasswordResetForm"
})(connect(null, { saveUserPassword })(PasswordResetForm));
