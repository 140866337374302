import { NIC_ENTER} from "../actions/index";

export default function (state = { data: [] }, action) {
    switch (action.type) {
        case NIC_ENTER:
            return action.payload;
        default:
            return null;
    }
}
