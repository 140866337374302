import React, { Component } from 'react';
import reduxDialog, { closeDialog, openDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';
import { bindActionCreators } from "redux";
import { API_ROOT_URL, MODALS, EVENTS } from '../config/';
import NotificationBarModal from './NotificationBarModal';

import { mobileNoUpdate, showNotificationModal } from "../actions/index";

class MobileEnterModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile_no: ""
        }

        this.updateInput = this.updateInput.bind(this);
    }

    updateInput(event) {
        this.setState({ mobile_no: event.target.value });
    }

    userMobileNumber() {
        if (this.state.mobile_no.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !(this.state.mobile_no.match(/0{5,}/))) {
            this.props.mobileNoUpdate({ "mobile_no": this.state.mobile_no, "nic_number": " " });
        } else {
            var data = { type: 'danger', message: 'Invalid mobile no' }
            this.props.showNotificationModal(data);
        }
    }


    render() {
        return (
            <div className="p-2">
                <div className="modal-header">
                    <h5 className="modal-title">Enter Mobile Number</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.MODALMOBILEENTER)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <NotificationBarModal />
                    <input type="text" className="form-control" placeholder="Enter Mobile Number" onChange={this.updateInput}></input>
                    <div className="pt-3"><button type="submit" className="btn btn-primary btn-block" onClick={() => this.userMobileNumber()}>SEND</button></div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ mobileNoUpdate, closeDialog, openDialog, showNotificationModal }, dispatch);
}

export default compose(reduxDialog({ name: 'MODAL_MOBILE_ENTER', centered: true }), connect(null, mapDispatchToProps))(MobileEnterModal);
