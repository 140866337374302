import { LOAD_USER_ACCOUNT, REGISTER, SOCIAL_LOGIN } from "../actions/index";

export default function (state = {}, action) {
    switch (action.type) {
        case LOAD_USER_ACCOUNT:
            return action.payload.data;
        case REGISTER:
            return action.payload;
        case SOCIAL_LOGIN:
            return action.payload;
        default:
            return state;
    }
}
