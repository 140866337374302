import { LOAD_PACKAGES } from "../actions/index";

export default function (state = [], action) {
    switch (action.type) {
        case LOAD_PACKAGES:
            return action.payload.data;
        default:
            return state;
    }
}
