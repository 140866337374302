import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Home as HomeIcon, FileText as FileIcon, User as UserIcon, LogIn as LoginIcon } from 'react-feather';
import { NavLink, withRouter } from "react-router-dom";
import { openDialog } from 'redux-reactstrap-modal';
import LoadingBar from 'react-redux-loading-bar';
import Popup from "reactjs-popup";
import _ from "lodash";


import { randomBuy, subscribe, buy, clearNextEvent, mobileNoUpdate, loadLanguage } from "../actions/index";
import { MODALS, EVENTS } from '../config/';
import nlb_logo from '../images/lucky_footer_logo.png';
import dialog_logo from '../images/dialoglogo.png';
import img from '../images/popup-image-luky1.jpg';


import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage
} from 'react-switch-lang';
import en from '../config/en.json';
import sin from '../config/sin.json';
import tml from '../config/tml.json';
setTranslations({ en, sin, tml });
setDefaultLanguage('en');
setLanguageCookie();

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectLang: 'en',
            bodyClass: "en",
            open: true
        };
        this.langChange = this.langChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }



    componentDidUpdate() {
        if (!(this.props.token === "" || this.props.token === null || this.props.token === "null")) {
            if (this.props.nextEvent) {
                const { event, data } = this.props.nextEvent;
                switch (event) {
                    case EVENTS.RANDOM_BUY:
                        this.props.randomBuy(data);
                        return "";
                    case EVENTS.BUY:
                        this.props.buy(data);
                        return "";
                    case EVENTS.SUBSCRIBE:
                        this.props.subscribe(data);
                        return "";
                    case EVENTS.ACCOUNT:
                        if (this.props.history.location.pathname !== "/account") {
                            this.props.history.push("/account");
                        } else {
                            window.location.reload();
                        }
                        return "";
                    default:
                        return "";
                }
            }

        } else if (!(this.props.nic_update === "null" || this.props.nic_update === null)) {
            if (this.props.nextEvent) {
                const { event } = this.props.nextEvent;
                switch (event) {
                    case EVENTS.RANDOM_BUY:
                        this.props.randomBuy(this.props.nic_update);
                        return "";
                    case EVENTS.BUY:
                        this.props.buy(this.props.nic_update);
                        return "";
                    case EVENTS.SUBSCRIBE:
                        this.props.subscribe(this.props.nic_update);
                        return "";
                    default:
                        return "";
                }

            }

        }

    }
    componentDidMount() {
        if (localStorage.getItem('language-loaded')) {
            this.setState({ selectLang: localStorage.getItem('language-loaded') });
            // this.props.loadLanguage(ls.get('language'));
            setLanguage(localStorage.getItem('language-loaded'));


            //Have to find a work around to this
            document.body.classList.remove('en');
            document.body.classList.remove('sin');
            document.body.classList.remove('tml');


            document.body.classList.add(localStorage.getItem('language-loaded'));
        }
    }

    langChange(event) {
        this.setState({ selectLang: event.target.value });
        this.props.loadLanguage(event.target.value);
        setLanguage(event.target.value);


        //Have to find a work around to this
        document.body.classList.remove('en');
        document.body.classList.remove('sin');
        document.body.classList.remove('tml');


        document.body.classList.add(event.target.value);

        if (this.props.token && this.props.token !== 'undefined') {
            // this.props.setLanguage({ "language": event.target.value });
        }
    }
    openModal() {
        this.setState({ open: true });
    }
    closeModal() {
        this.setState({ open: false });
    }


    renderPopup() {


        // var pointer_style = {
        //     cursor: 'pointer',
        //     display:'block',
        //   };

        // return (
        //     <Popup
        //         open={this.state.open}
        //         modal
        //         closeOnDocumentClick
        //         onClose={this.closeModal} >
        //         <div>
        //             <a style={pointer_style} title="Close" className="close" onClick={this.closeModal}>
        //                 &times;
        //     </a>

        //             <span style={pointer_style}> <img src={img} onClick={() =>
        //         this.props.openDialog("MODAL_BUY", {"draw_id":516,"lottery_id":4,"jackpot_price":"60,000,000.00","lottery_name":"Govi Setha","lottery_price":20,"sales_close_time":"2019-09-26 19:00:00","draw_date_time":"2019-09-26 21:00:00","lottery_logo_url":"https://s3-ap-southeast-1.amazonaws.com/lms-logo/Govi%20Setha.png","lottery_image_url":"https://s3-ap-southeast-1.amazonaws.com/lms-logo/images/ticket-img-govisetha.png","max_ticket_limit":50,"fields":[{"field":"Special","values":"A-Z","special_field":true,"bonus_number":null,"format":"Alphabetic"},{"field":"Number","values":"0-99","special_field":false,"bonus_number":null,"format":"Numeric"},{"field":"Number","values":"0-99","special_field":false,"bonus_number":null,"format":"Numeric"},{"field":"Number","values":"0-99","special_field":false,"bonus_number":null,"format":"Numeric"},{"field":"Number","values":"0-99","special_field":false,"bonus_number":null,"format":"Numeric"}],"light_color":"#ff6666","dark_color":"#ff6699","is_ongoing":true,"draw_number":2252,"is_search_enable":false,"is_ticket_soldout":false})
        //       } />
        //             </span>
        //         </div>

        //     </Popup>
        // )
    }


    render() {
        const { t } = this.props;
        var lan = getLanguage();


        return (
            <div className="menu-bar">
                <div className="d-flex flex-column flex-md-row align-items-stretch col-12">
                    <div className="px-0 d-flex mx-lg-auto justify-content-between justify-content-lg-around align-items-center text-center">
                        <img src={nlb_logo} alt="NLB Logo" class="logo" />
                        {/* <img src={dialog_logo} alt="Dialog Logo" className="ml-4" /> */}
                        <div className="language d-flex d-md-none mobile-menu flex-column flex-md-row ml-md-auto mx-1 align-items-end">
                            <select className="languageSelect" onChange={this.langChange} value={this.state.selectLang}>
                                <option value="en">English</option>
                                <option value="sin">SINHALA</option>
                                <option value="tml">TAMIL</option>
                            </select>
                        </div>
                    </div>
                    <div className="dsktp-menu">

                        {this.renderPopup()}
                        {/*
                        <ul className="nav nav-pills nav-fill nav-justified p-0">
                            <li className="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link mx-1 d-flex flex-column flex-md-row align-items-center" to="/">
                                    <div><HomeIcon size={16} /></div>
                                    <div>Home</div>
                                </NavLink >
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link mr-1 d-flex flex-column flex-md-row align-items-center" to="/results">
                                    <div><FileIcon size={16} /></div>
                                    <div>Results</div>
                                </NavLink >
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link mr-1 d-flex flex-column flex-md-row align-items-center" to="/account">
                                    <div><UserIcon size={16} /></div>
                                    <div>Account</div>
                                </NavLink >
                            </li>
                        </ul>
                        */}

                        <div className="d-flex nav nav-pills nav-fill nav-justified p-0">
                            <NavLink exact activeClassName="active" className="nav-link dsktp-d-flex mobile-menu d-flex flex-column flex-md-row mx-1 align-items-center" to="/">
                                <div><HomeIcon size={16} /></div>
                                <div>{t("menu.home_button")}</div>
                            </NavLink >
                            <NavLink activeClassName="active" className="nav-link dsktp-d-flex d-flex mobile-menu flex-column flex-md-row mx-1 align-items-center" to="/results">
                                <div><FileIcon size={16} /></div>
                                <div>{t("menu.result")}</div>
                            </NavLink >

                            <div className="language d-none nav-link d-md-flex mobile-menu flex-column flex-md-row ml-md-auto mx-1 align-items-center">
                                <select className="languageSelect" onChange={this.langChange} value={this.state.selectLang}>
                                    <option value="en">{t("language_switch.english")}</option>
                                    <option value="sin">{t("language_switch.sinhala")}</option>
                                    <option value="tml">{t("language_switch.tamil")}</option>
                                </select>
                            </div>

                            <NavLink activeClassName="active" className="nav-link dsktp-d-flex d-flex mobile-menu flex-column flex-md-row mx-1 align-items-center" to="/account">

                                <div><UserIcon size={16} /></div>
                                <div>{t("menu.account")}</div>
                            </NavLink >
                        </div>
                    </div>
                </div>
                <LoadingBar style={{ backgroundColor: '#ffaf42' }} />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ openDialog, randomBuy, buy, subscribe, clearNextEvent, loadLanguage }, dispatch);
}

function mapStateToProps({ nextEvent, token, nic_update }) {
    return { nextEvent, token, nic_update };
}
export default withRouter(translate(connect(mapStateToProps, mapDispatchToProps)(Menu)));
