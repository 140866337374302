import React, { Component } from "react";
import reduxDialog, { closeDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import { LOTTERY_LIST } from "../config/";
import _ from "lodash";

import { subscribe } from "../actions/index";
import { MODALS, WEEK_DAYS, CONVENIENT_FEE } from "../config";
import { translate, getLanguage } from "react-switch-lang";

class SubscribeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      loadEditText: false
    };
    this.updateInput = this.updateInput.bind(this);
  }

  renderCount(count) {
    const {
      t,
      data: { lottery_price, light_color, dark_color }
    } = this.props;
    var style = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: ""
    };

    if (this.state.count === count) {
      style.color = "#ffffff";
      style.backgroundColor = dark_color;
      style.selected = " selected";
    }

    return (
      <div key={count} className="col-3 p-1">
        {count === 10 ? (
          <button
            className="btn btn-block mr-1"
            style={style}
            onClick={() => this.setState({ loadEditText: true })}
          >
            <h1>+</h1>
            <small>{t("const_file.other")}</small>
          </button>
        ) : (
          <button
            className={"btn btn-block mr-1" + style.selected}
            style={style}
            onClick={() => this.setState({ count })}
          >
            <h1>{count}</h1>
            <small>
              {t("const_file.rs")}
              {count * lottery_price}
            </small>
          </button>
        )}
      </div>
    );
  }

  updateInput(event) {
    event.preventDefault();
    // this.setState({ mobile_pin: event.target.value });

    if (event.target.value <= 0) {
      if (event.target.value !== "") {
        this.setState({ count: 1 });
        ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
      }
    } else {
      this.setState({ count: event.target.value });
    }
  }

  renderEditText() {
    const { t } = this.props;
    return (
      <div className="col-12 p-1">
        {
          <input
            type="number"
            ref="ticket_count_sub"
            min="1"
            className="form-control"
            placeholder={t("subscribemodal.enter_no_tickets_buy")}
            onChange={this.updateInput}
          />
        }
      </div>
    );
  }

  //placeholder={t("subscribemodal.enter_no_tickets_buy")}

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    return "lottery_tickets." + pack.key;
  }

  render() {
    console.log(this.props);
    const {
      data: {
        lottery_name,
        light_color,
        dark_color,
        package_id,
        draw_lotteries
      },
      subscribe,
      t
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff"
    };
    var date_on_lottery = t("subscribemodal.draw_available");
    var draw_dates = draw_lotteries[0].draw_dates;
    for (var i = 0; i < draw_dates.length; i++) {
      if (draw_dates.length == 1) {
        var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
        var day_full_name = search_day.id;
        date_on_lottery =
          date_on_lottery + " " + t("day_names." + day_full_name + "");
      } else {
        if (i == Number(draw_dates.length) - Number(1)) {
          //  if (draw_dates.length!=1) {

          var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
          var day_full_name = search_day.id;
          date_on_lottery =
            date_on_lottery +
            " " +
            t("const_file.and") +
            " " +
            t("day_names." + day_full_name + "") +
            " ";
        } else {
          var search_day = WEEK_DAYS.find(item => item.id == draw_dates[i]);
          var day_full_name = search_day.id;
          date_on_lottery =
            date_on_lottery + " " + t("day_names." + day_full_name + "") + ",";
        }
      }
    }

    return (
      <div className="p-3">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("subscribemodal.subscribe")}{" "}
            {t(this.loadLotteryName(lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.props.closeDialog(MODALS.SUBSCRIBE)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h4>{t("subscribemodal.no_tickets_sub")}</h4>
          <div className="my-3 px-3 row">
            {this.state.loadEditText
              ? this.renderEditText()
              : [1, 2, 5, 10].map(count => {
                  return this.renderCount(count);
                })}
          </div>
          <h5 className="my-3 bolded px-2 text-center">{date_on_lottery}</h5>
          <p className="my-3  text-center">{t("const_file.convenient_fee")}</p>
          <button
            className="btn btn-block mr-1"
            style={buttonStyle}
            id="subscribe_button_id"
            onClick={() =>
              subscribe({
                package_id,
                subscribe_count: this.state.count,
                lottery_name: lottery_name
              })
            }
          >
            {t("subscribemodal.subscribe")}
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ subscribe, closeDialog }, dispatch);
}

export default compose(
  translate,
  reduxDialog({ name: MODALS.SUBSCRIBE, centered: true }),
  connect(
    null,
    mapDispatchToProps
  )
)(SubscribeModal);
