import React, { Component } from 'react';

import { connect } from "react-redux";

import {
    translate,
    getLanguage
  } from 'react-switch-lang';

class NewTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: props.selectedTicket
        }
    }

    selectTicket(ticket_id) {
        const { onSelect, onDeselect ,selected} = this.props;
        this.setState({ selected: !this.state.selected });
        if (this.state.selected) {
            onDeselect(ticket_id);
        } else {
            onSelect(ticket_id);
        }
    }

    renderTicketFields(fields) {
        const { color } = this.props;

        return fields.map((field, index) => {
            var style = {
                background: "#ffffff"
            };

            if (field.special_number) {
                style.background = color;
                style.color = "#ffffff";
            }

            return <div className="px-2 py-1 mr-1 ticket-number" style={style} key={index}>{field.value}</div>
        });
    }

    render() {
        const { ticket, color,selectedTicket } = this.props;
        const { t } = this.props;
        var style = { border: `1px solid #eeeeee` };
        // this.setState({ selected:selectedTicket});
        if (this.state.selected) {
            style = { border: `1px solid ${color}` };
        }

        return (
            <div key={ticket.lottery_ticket_id} className="col-12 p-2 d-flex flex-row justify-content-between bg-light rounded my-1" style={style} onClick={() => this.selectTicket(ticket.lottery_ticket_id)} >
                {this.renderTicketFields(ticket.fields)}
                <button className="btn btn-sm" style={{ background: color, borderColor: color, color: "#ffffff" }} >
                    {
                        this.state.selected ? t("searchmodal.deselect") : t("searchmodal.select")
                    }
                   
                </button>
            </div>
        );
    }
}

export default translate(connect()(NewTicket));