import React, { Component } from 'react';
import reduxDialog, { closeDialog } from 'redux-reactstrap-modal';
import { connect } from "react-redux";
import { compose } from 'recompose';

import { bindActionCreators } from "redux";

import { deactivate, loadLotteryPackages, buy } from "../actions/index";
import { MODALS, EVENTS } from '../config';

class WarningModal extends Component {
    constructor(props) {
        super(props);
    }

    yes() {
        const { closeDialog, state, deactivate, loadLotteryPackages, buy, data: { action, props } } = this.props;
        switch (action) {
            case EVENTS.DEACTIVATE:
                deactivate(props);
                return state;
            case EVENTS.BUYALL:
                buy(props);
                return state;
            default:
                closeDialog(MODALS.WARNING);
                return "";
        }
    }

    render() {
        const { data: { title, message, action } } = this.props;
        console.log(this.props);
        let submit_button = "Yes";
        let cancel_button = "No";
        if (action === EVENTS.BUYALL) {
            submit_button = "Ok";
            cancel_button = "Cancel";
        }
        return (
            <div className="p-3">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" onClick={() => this.props.closeDialog(MODALS.WARNING)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h4>{message}</h4>
                    <div className="row mt-3">
                        <div className="col-6 pr-1">
                            <button className="btn btn-block btn-success" onClick={() => this.yes()}>{submit_button}</button>
                        </div>
                        <div className="col-6 pl-1">
                            <button className="btn btn-block btn-danger" onClick={() => this.props.closeDialog(MODALS.WARNING)}>{cancel_button}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ closeDialog, deactivate, loadLotteryPackages, buy }, dispatch);
}

export default compose(reduxDialog({ name: MODALS.WARNING, centered: true }), connect(null, mapDispatchToProps))(WarningModal);
