import { DIALOG_APP_KP} from "../actions/index";

export default function (state = {kp:""}, action) {
    switch (action.type) {
        case DIALOG_APP_KP:
            return action.payload;
        default:
            return state;
    }
}
