import { LOAD_WALLET_BALANCE } from "../actions/index";

export default function (state = 0, action) {
    switch (action.type) {
        case LOAD_WALLET_BALANCE:
            return action.payload.data.available_balance || 0;
        default:
            return state;
    }
}
