import { LOAD_RESULTS , RESULT_LOADING} from "../actions/index";


const data = { "data": [], "page": { "total": 0, "limit": 0, "offset": 0 } };
export default function (state = data, action) {
    switch (action.type) {
        case LOAD_RESULTS:
            return action.payload;
        default:
            return state;
    }
}
