import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { withRouter } from "react-router-dom";
import { openDialog } from "redux-reactstrap-modal";
import axios from "axios";

import { bindActionCreators } from "redux";
import {
  loadAccount,
  logout,
  setNextEvent,
  loadMyLottery,
  loadAvaliableLotteries,
  loadHeaderMobile
} from "../actions/index";

import { MODALS, EVENTS, LIMIT, API_ROOT_URL } from "../config/";
import {
  X as CloseIcon,
  Filter as FilterIcon,
  RefreshCcw as Refresh
} from "react-feather";
import { translate, getLanguage } from "react-switch-lang";
import MyTicket from "../components/MyTicket";
import MyPackage from "../containers/MyPackage";
import InfiniteScroll from "react-infinite-scroll-component";
import { LOTTERY_LIST } from "../config/";
import _ from "lodash";

class Account extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      offset: 0,
      hasMore: true,
      result_loading: false,
      draw_number: "",
      drawIdValue: "Select",
      offsetSearch: 0,
      searchDate: "",
      width: props.width,
      show_filter: false
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    const {
      available_packages,
      token,
      history,
      openDialog,
      setNextEvent,
      loadAccount,
      loadMyLottery,
      loadAvaliableLotteries
    } = this.props;
    // this.props.loadHeaderMobile();
    this.setState({
      width: window.innerWidth
    });

    if (token === "" || token === null) {
      history.push("/");
      // openDialog(MODALS.LOGIN);
      // setNextEvent({ event: EVENTS.ACCOUNT });
      openDialog(MODALS.LOGIN);
      setNextEvent({ event: EVENTS.ACCOUNT });
    } else {
      loadAccount();
      loadMyLottery();
      loadAvaliableLotteries();
    }
  }

  renderTickets() {
    const { myTickets } = this.props;

    return myTickets.data.map(ticket => {
      return <MyTicket key={ticket.serial_number} result={ticket} />;
    });
  }

  logout() {
    this.props.history.push("/");
    this.props.logout();
  }

  resetPassword() {
    this.props.openDialog(MODALS.PASSWORDRESET);
  }

  renderPackages() {
    const { myPackages } = this.props;
    console.log("Re rendering My Packs");
    return myPackages.map(pack => {
      return <MyPackage key={pack.package_id} result={pack} />;
    });
  }

  toggleFilter() {
    window.scrollTo(0, 0);
    this.setState({ show_filter: !this.state.show_filter });
    this.setState({ searchDate: "", draw_number: "", drawIdValue: "" });
    this.props.loadMyLottery(0);
  }

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    return "lottery_tickets." + pack.key;
  }

  renderSelect() {
    const { available_packages, t } = this.props;
    if (available_packages.length != 0) {
      return available_packages.data.map(result => {
        return (
          <option key={result.lottery_id} value={result.lottery_id}>
            {t(this.loadLotteryName(result.lottery_name))}
          </option>
        );
      });
    }
  }

  changeSelectDrawId(event) {
    this.setState({ drawIdValue: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  handleDateChange(event) {
    this.setState({ searchDate: event.target.value });
    // this.setState({drawIdValue: event.target.value});
  }

  filterButtonClick() {
    this.setState({ show_filter: true, hasMore: true });
    var item = {};
    var offsetUpdated = 0;
    this.setState({ offsetSearch: 0 });
    if (
      !this.state.drawIdValue &&
      !this.state.draw_number &&
      !this.state.searchDate
    ) {
      this.props.loadMyLottery(offsetUpdated);
    } else {
      item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number,
        search_date: this.state.searchDate
      };
      this.props.loadMyLottery(offsetUpdated, item, false);
    }
  }

  resetButtonClick() {
    this.setState({
      offsetSearch: 0,
      drawIdValue: "",
      draw_number: "",
      searchDate: "",
      hasMore: true
    });
    this.props.loadMyLottery();
  }

  updateInputDrawId(event) {
    this.setState({ draw_number: event.target.value });
  }

  loadMore() {
    const { myTickets } = this.props;
    // if (!this.props.result_loading) {
    //   return;
    // }
    if (!this.state.show_filter) {
      var hasMore;
      if (myTickets.page.total > LIMIT) {
        if (this.state.offset < myTickets.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
      this.setState({ hasMore: hasMore, result_loading: true });
      if (hasMore) {
        this.setState({ offset: this.state.offset + LIMIT }, () => {
          this.props.loadMyLottery(this.state.offset);
        });
      }
    } else {
      var hasMore;
      if (myTickets.page.total > 10) {
        if (this.state.offsetSearch < myTickets.page.total) {
          hasMore = true;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }

      this.setState({ hasMore: hasMore, result_loading: true });
      var item = {
        lottery_id: this.state.drawIdValue,
        draw_number: this.state.draw_number,
        search_date: this.state.searchDate
      };
      if (hasMore) {
        this.setState({ offsetSearch: this.state.offsetSearch + LIMIT }, () => {
          this.props.loadMyLottery(this.state.offsetSearch, item, false);
        });
      }
    }
  }

  refresh() {}

  scrolled() {
    console.log("Scrolled");
  }

  render() {
    const {
      user: { name, mobile_no },
      wallet,
      myPackages,
      myTickets,
      t
    } = this.props;

    console.log(myTickets);
    return (
      <div className="row p-1">
        <div className="col-12 my-2">
          {mobile_no ? (
            <div className="bg-light border rounded col-12 p-3">
              <h3>{name}</h3>
              <p>
                {t("loginmodal.mobile_no") + " :" + mobile_no}
                {wallet ? (
                  <span>
                    <br />
                    {/* {t("myaccount.wallet_balance") + " "} {wallet} */}
                  </span>
                ) : (
                  ""
                )}
              </p>
              <button
                className="btn btn-primary btn-sm mr-3"
                onClick={() => this.resetPassword()}
              >
                {t("myaccount.change_password")}
              </button>
              <button
                onClick={() => this.logout()}
                className="btn btn-secondary btn-sm"
              >
                {t("myaccount.logout")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-12 my-2">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                {t("myaccount.my_ticket")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                {t("myaccount.my_packages")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            className="border border-top-0 rounded-bottom"
            activeTab={this.state.activeTab}
          >
            <TabPane tabId="1">
              {this.state.width < 768 && !this.state.show_filter ? (
                <button
                  onClick={() => this.toggleFilter()}
                  className="btn btn-filter-show filter_btn"
                >
                  <FilterIcon size={16} />
                </button>
              ) : (
                <div className="col-12 p-3 pt-5">
                  {this.state.width < 768 && this.state.show_filter ? (
                    <div className="closeIcon float-right">
                      <CloseIcon
                        color="black"
                        size={16}
                        onClick={() => this.toggleFilter()}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <h4>{t("myaccount.search_ticket")}</h4>
                  <form>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("menu.lottery_name")}</label>
                          <select
                            onChange={this.changeSelectDrawId.bind(this)}
                            value={this.state.drawIdValue}
                            className="form-control form-control-lg"
                          >
                            <option value="Select">{t("menu.select")}</option>
                            {this.renderSelect()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("menu.draw_no")}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="drawNumber"
                            placeholder={t("menu.draw_no")}
                            value={this.state.draw_number}
                            onChange={this.updateInputDrawId.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{t("home.date")}</label>
                          <input
                            onChange={this.handleDateChange.bind(this)}
                            className="form-control zmDatePicker"
                            value={this.state.searchDate}
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 d-md-flex">
                        {this.state.width > 768 ? (
                          <div className="form-group">
                            <label className="dummyLabel" />
                            <button
                              type="submit"
                              className="btn btn-primary btn-search-filter reset mx-auto"
                              onClick={() => this.resetButtonClick()}
                            >
                              <Refresh
                                size={20}
                                onClick={() => this.toggleFilter()}
                              />
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group  ml-md-1">
                          <label className="dummyLabel" />
                          <button
                            type="submit"
                            className="btn btn-primary btn-search-filter mx-auto"
                            onClick={() => this.filterButtonClick()}
                          >
                            {t("myaccount.search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div className="py-3">
                {myTickets.data.length ? (
                  <InfiniteScroll
                    pullDownToRefresh
                    dataLength={myTickets.data.length} //This is important field to render the next data
                    onScroll={this.scrolled.bind(this)}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8595; Pull down to refresh
                      </h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593; Release to refresh
                      </h3>
                    }
                    next={this.loadMore.bind(this)}
                    refreshFunction={this.refresh.bind(this)}
                    hasMore={this.state.hasMore}
                    loader={
                      <h4 style={{ textAlign: "center", color: "white" }}>
                        Loading...
                      </h4>
                    }
                    endMessage={
                      <p style={{ textAlign: "center", color: "white" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    <div className="row mx-2 mx-lg-2">
                      {this.renderTickets()}
                    </div>
                  </InfiniteScroll>
                ) : (
                  <p className="p-3">{t("myaccount.no_purchase_ticket")}</p>
                )}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="py-3">
                <div className="row mx-2 mx-lg-2">
                  {myPackages.length ? (
                    this.renderPackages()
                  ) : (
                    <p className="p-3">{t("myaccount.no_subcribe_package")}</p>
                  )}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog,
      loadAccount,
      logout,
      setNextEvent,
      loadMyLottery,
      loadAvaliableLotteries
    },
    dispatch
  );
}

function mapStateToProps({
  token,
  user,
  myTickets,
  myPackages,
  wallet,
  available_packages
}) {
  return { token, user, myTickets, myPackages, wallet, available_packages };
}

export default withRouter(
  translate(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Account)
  )
);
