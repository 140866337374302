import { LOGGED_IN, LOGGED_OUT } from "../actions/index";

export default function (state, action) {

    switch (action.type) {
        case LOGGED_IN:
            console.log(action.payload.data.exchange_token);
            localStorage.setItem('TOKEN', action.payload.data.exchange_token);
            return localStorage.getItem('TOKEN')==="null"||localStorage.getItem('TOKEN')===null?"":localStorage.getItem('TOKEN');
        case LOGGED_OUT:
            localStorage.clear();
            return localStorage.getItem('TOKEN')==="null"||localStorage.getItem('TOKEN')===null?"":localStorage.getItem('TOKEN');
        default:
            return localStorage.getItem('TOKEN')==="null"||localStorage.getItem('TOKEN')===null?"":localStorage.getItem('TOKEN');
    }
}
